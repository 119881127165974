import * as types from './mutation-types'
/* eslint-disable */

export const getPaymentById = ({commit}, id) => {
    return new Promise((resolve, reject) => {
        window.axios.get('show/'+id+'/payment').then((response) => {
            commit(types.SET_CRM_CURRENT_PAYMENT, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const createPayment = ({commit}, data) => {

    let formData = new FormData();
    data.files.forEach((file, index) => {
        formData.append(`files[${index}]`, file);
    });
    formData.append('payment_date', data.payment_date)
    formData.append('notes', data.notes)
    formData.append('invoice_id', data.invoice_id)
    formData.append('payment_type_id', data.payment_type_id)
    formData.append('type', data.type)

    if(data.percentage){
        formData.append('percentage', data.percentage)
    }
    if(data.amount){
        formData.append('amount', data.amount)
    }
    formData.append('_method', 'POST')

    return new Promise((resolve, reject) => {
        window.axios.post('store/invoice/payment', formData, {headers: {'Content-type': 'multipart/form-data'}}).then((response) => {
            resolve(response)
            commit(types.ADD_PAYMENT, response.data)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const sendPayment = ({commit}, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post('send/payment/'+ data.paymentId + '/email', data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const cancelPaymentMail = ({commit}, id) => {
    return new Promise((resolve, reject) => {
        window.axios.post('cancel-email/payment/'+ id + '/email').then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const downloadPayment = ({commit}, id) => {
    return new Promise((resolve, reject) => {
        window.axios.get(`get/payment/pdf/${id}`, {
            responseType: 'blob'
        }).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const loadPaymentStatus = ({commit}) => {
    return new Promise((resolve, reject) => {
        window.axios.get('payment/status/enum').then((response) => {
            commit(types.LOAD_CRM_PAYMENT_STATUS, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const validatePayment = ({commit}, data) => {
    return new Promise((resolve, reject) => {
        window.axios.patch('update/payment/' + data.payment_id + '/status', data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const cancelPayment = ({commit}, data) => {
    return new Promise((resolve, reject) => {
        window.axios.patch('cancel/payment/' + data.payment_id, data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}