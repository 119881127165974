<template>
  <div class="background-image">
    <div class="container">
      <div class="content">
        <span class="icon text-primary fw-bolder mb-10" style="font-size: 90px;"> 404</span>
        <p class="mb-10" style="font-size: 25px;">{{$t('page_not_found_text')}}</p>
        <button class="btn btn-primary btn-md px-20 fw-bolder" @click="() => $router.go(-1)">{{$t('back')}}</button>
      </div>
    </div>
    <div class="text-center">
      <img src="/assets/media/404.png" alt=""
                class="mw-100 h-225px h-sm-400px" />
    </div>
  </div>
</template>
<script setup>

</script>
<style scoped>
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
}

.content {
  text-align: center;
}

.icon {
  font-size: 50px;
  margin-bottom: 20px;
}

.background-image {
  background: 
      linear-gradient(rgba(0, 114, 206, 0.1), rgba(240, 120, 23, 0.1)),
      url('../../public/assets/media/empty_bg.png') no-repeat center center,
      url('../../public/assets/media/empty_bg.png') no-repeat center center;
  background-size: cover, cover, cover;
  height: 100vh;
  width: 100%; 
}
</style>
