<template>
  <div class="d-flex flex-column flex-root bg-white" style="height: 100vh!important;">
    <div class="d-flex flex-column flex-lg-row flex-column-fluid" 
         style="background: linear-gradient(rgba(0, 114, 206, 0.1), rgba(240, 120, 23, 0.1)); height: 100vh!important;">
         
      <div class="d-flex flex-column flex-lg-row-auto w-lg-50 h-100 d-none d-lg-flex" 
           style="position: relative;">
        <div class="d-flex flex-column justify-content-center align-items-center text-center px-10 h-100" 
             style="margin-bottom: 50px;">
          <div class="aside-logo flex-column-auto mb-10" id="kt_aside_logo">
             <img alt="Logo" src="/assets/media/logo.png" class="h-70px" />
          </div>
          <p class="pb-5 fs-3 d-none" style="color: #000000;">
            Lorem ipsum dolor sit amet. Eos eligendi optio ex amet harum vel mollitia<br> atque qui cumque quod qui omnis explicabo ut veniam eligendi. Eum illum sunt
          </p>
          <div class="d-flex justify-content-center align-items-center mt-5">
            <img alt="Logo" :src="$route.meta.image" style="height: 50vh!important;" class="mt-10" />
          </div>
        </div>
      </div>

      <div class="d-flex flex-column d-lg-none flex-column-fluid justify-content-center align-items-center w-100">
        <div class="d-flex flex-column justify-content-center align-items-center text-center px-10 py-5">
          <img alt="Logo" src="/assets/media/logo.png" class="h-70px mb-4" />
          <p class="pb-5 fs-3 d-none" style="color: #000000;">
            Lorem ipsum dolor sit amet. Eos eligendi optio ex amet harum vel mollitia<br> atque qui cumque quod qui omnis explicabo ut veniam eligendi. Eum illum sunt
          </p>
          <img alt="Logo" :src="$route.meta.image" class="h-400px mb-5" />
        </div>
      </div>
      
      <div class="d-flex flex-column flex-lg-row-fluid justify-content-center align-items-center w-lg-50 mb-10">
        <div class="card shadow-sm w-100 mx-lg-auto" style="max-width: 550px;">
          <div class="text-center pt-20">
            <img alt="Logo" src="/assets/media/logo.png" class="h-50px" />
          </div>
          <router-view></router-view>
        </div>
      </div>
      <div class="fixed-top-right">
        <LangDropdown />
      </div>
    </div>
  </div>
</template>

<script setup>

import LangDropdown from "@/components/LangDropdown.vue";
import {nextTick, onMounted, onUpdated, computed} from "vue";
import { appVersion } from "@/mixins/Helpers";

onMounted(() => {
  nextTick(() => {
    initComponents()
  })
})

onUpdated(() => {
  nextTick(() => {
    initComponents()
  })
})

const versionApp = computed(() => appVersion.value)

function initComponents(){
  KTMenu.init();
  KTDrawer.init();
}

</script>

<style scoped>
.fixed-top-right {
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 999;
}
</style>
