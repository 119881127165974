import * as types from './mutation-types'


export default {
  [types.LOAD_FREQUENCY_ENUM] (state, data) {
    state.frequencyEnum = data
  },

  [types.LOAD_CONTEXT_ENUM] (state, data) {
    state.contextEnum = data
  },
  [types.LOAD_TEMPLATES] (state, data) {
    state.templates = data
  },
  [types.LOAD_SUITS_APPS] (state, data) {
    state.suiteApps = data.data
  },
}
