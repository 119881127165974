import * as types from './mutation-types'
import {LOAD_RECURRING_INVOICE_FREQUENCY} from "./mutation-types";
import {invoiceRecurringStatus} from "@/store/modules/invoices/getters";

export default {
  [types.LOAD_INVOICES](state, data) {
    state.invoices = data.data
  },

  [types.ADD_INVOICE](state, data) {
    state.invoices.push(data)
  },

  [types.DELETE_INVOICE](state, invoiceId) {
    state.invoices = state.invoices.filter(el => el.id !== invoiceId)
  },

  [types.SET_CURRENT_INVOICE](state, invoice) {
    state.currentInvoice = invoice.data
  },

 [types.SET_CURRENT_RECURRING_INVOICE](state, recurrentInvoice) {
    state.currentRecurrentInvoice = recurrentInvoice.data
  },

  [types.LOAD_INVOICE_STATUS](state, data) {
    state.invoiceStatus = data
  },
    [types.LOAD_RECURRING_INVOICE_STATUS](state, data) {
    state.invoiceRecurringStatus = data
  },

  [types.LOAD_RECURRING_INVOICE_FREQUENCY](state, data) {
    state.recurringInvoiceFrequency = data
  },


  [types.ADD_RECURRING_INVOICE](state, data) {
    state.recurringInvoices.push(data)
  },

}
