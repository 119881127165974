<template>
    <div class="modal fade" :id="id" tabindex="-1">
      <div class="modal-dialog" >
        <form @submit.prevent="submitForm" class="form">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">{{ $t('appointments.reportText') }}</h5>
              <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" @click="closeModal">
                  <span class="svg-icon svg-icon-1">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)"
                            fill="currentColor" />
                      <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)"
                            fill="currentColor" />
                    </svg>
                  </span>
              </div>
            </div>
            <div class="modal-body">
                <div class="row">
                  <InputDate
                      :parent-id="'#'+id"
                      containerClass="col-12 col-md-6 col-lg-6 mt-auto mb-5"
                      name="appointments-start_time"
                      :label="$t('appointments.label.start_date')"
                      labelClass="form-label fs-6 text-black"
                      :inputClass="'form-control form-control-sm form-control-solid'"
                      :isRequired="true" size="lg"
                      dateFormat="dd-MM-yyyy HH:mm"
                      :enableTime="true"
                      :placeholder="$t('appointments.label.start_date')"
                      v-model="formData.start_time"
                  />
                  <InputDate
                      :parent-id="'#'+id"
                      containerClass="col-12 col-md-6 col-lg-6 mt-auto mb-5"
                      name="appointments-end_time"
                      :label="$t('appointments.label.end_date')"
                      labelClass="form-label fs-6 text-black"
                      :inputClass="'form-control form-control-sm form-control-solid'"
                      :isRequired="true" size="lg"
                      dateFormat="dd-MM-yyyy HH:mm"
                      :enableTime="true"
                      :placeholder="$t('appointments.label.end_date')"
                      v-model="formData.end_time"
                  />
                  <Input
                      :parent-id="'#'+id"
                      containerClass="form-group col-12 col-md-6 col-lg-6 mt-auto mb-5"
                      :name="`location`"
                      type="text"
                      :label="$t('appointments.label.place')"
                      labelClass="form-label fs-6 text-black"
                      :inputClass="'form-control form-control-lg form-control-solid'"
                      :isRequired="true" size="lg"
                      :placeholder="$t('appointments.label.place')"
                      v-model="formData.location"
                  />
                  <Input
                      :parent-id="'#'+id"
                      containerClass="form-group col-12 col-md-6 col-lg-6 mt-auto mb-5"
                      :name="`link`"
                      type="url"
                      :label="$t('appointments.label.link')"
                      labelClass="form-label fs-6 text-black"
                      :inputClass="'form-control form-control-lg form-control-solid'"
                      :isRequired="false" size="lg"
                      :placeholder="$t('appointments.label.link')"
                      v-model="formData.link"
                  />
                  <CustomSelect2
                    :parent-id="'#'+id"
                    container-class="form-group col-12 col-md-12 mt-auto mb-5"
                    :multiple="false"
                    :show-search="true"
                    :selectClass="'form-select form-control-sm form-select-solid'"
                    :name="`time_zone_id_id`"
                    :isRequired="false"
                    :label="$t('appointments.label.gmt')"
                    labelClass="form-label fs-6 text-black"
                    :placeholder-text="$t('appointments.label.gmt')"
                    v-model="formData.time_zone_id"
                    :IsInputGroup="true"
                >
                  <option v-for="(timeZone, index) in timeZones" :key="index" :value="timeZone.id">
                    ({{timeZone?.diff_from_gtm}}) {{ timeZone.name }}
                  </option>
                  <template #custom>
                    <div class="icon-wrapper">
                      <i class="fas fa-globe" style="color: rgba(100, 110, 123, 1)"></i>
                    </div>
                  </template>
                </CustomSelect2>
                </div>
            </div>
            <div class="modal-footer">
              <Button type="cancel" id="cancel_btn" btn-class="btn-sm me-3" size="7px 30px" btn-text="button.cancel" @click="closeModal"></Button>
              <Button btn-class="btn-sm me-3" type="submit" id="submit_btn" btn-text="button.save" size="7px 30px" :is-loading="isLoading"></Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </template>
  
  <script setup>
    import {ref, defineEmits, defineProps, computed, onMounted} from "vue";
    import Input from "@/components/Input.vue";
    import {useStore} from "vuex";
    import Button from "@/components/Button.vue"
    import InputDate from "@/components/InputDate2.vue";
    import CustomSelect2 from "@/components/CustomSelect2.vue";

    const store = useStore()
    
    const emits = defineEmits(['load'])
    const props = defineProps({
        id: {
          type: String,
          required: true
        },
        form: {
          type: Object,
          default: {
            appointment_id: '',
            start_time: null,
            end_time: null,
            location: '',
            link: '',
            time_zone_id: ''
          }
        }
    })

    const month = ref(['dayGridMonth'])
    const size = ref({
        contentHeight: 150,
        height: 250
    })
    const isLoading = ref(false)

    const timeZones = computed(() => store.getters["appointments/getTimeZones"])
  
    const formData = ref(props.form)

    function closeModal(){
        $('#'+props.id).modal('toggle')
    }

    function submitForm(){
      isLoading.value = true
      store.dispatch('appointments/reportAppointment', formData.value)
          .then((response) => {
              emits('load')
              closeModal()
              isLoading.value = false
              window.toastr['success'](i18n.global.t('notification.change_date_created'))
            })
          .catch(err => {
            isLoading.value = false
          })
    }
  
    onMounted(() => {
      store.dispatch('appointments/loadTimeZones')
    })
</script>
    
<style scoped>
  .icon-wrapper {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .icon-wrapper i {
    position: relative;
    font-size: 1.5em;
  }

  .icon-wrapper .icon-text {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 9px;
    color: white;
    margin-top: 8px;
  }
</style>