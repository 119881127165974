import pipelines from "./modules/pipeline"
import tags from "./modules/tags"
import sources from "./modules/sources"
import reasons from "./modules/reasons"
import activityTypes from "./modules/activityTypes"
import steps from "./modules/steps"
import activity from "./modules/activity";
import estimates from "./modules/estimates";
import statistics from "./modules/stats"
import crmPayments from "./modules/payments"
import appointments from "./modules/appointments"
import appointmentTypes from "./modules/appointmentTypes"
import advances from "./modules/advances"
import salesTarget from "./modules/salesTarget"

export default {
  modules: {
    pipelines,
    tags,
    sources,
    reasons,
    activityTypes,
    steps,
    activity,
    estimates,
    statistics,
    crmPayments,
    appointments,
    appointmentTypes,
    advances,
    salesTarget
  }
}
