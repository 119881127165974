<template>
    <div class="modal fade" :id="id" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="staticBackdrop" aria-hidden="true">
      <div class="modal-dialog modal-md" role="document">
        <form @submit.prevent="createPipelineData" class="form">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">{{ $t('pipelines.creation') }}</h5>
              <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" @click="closeModal">
                <span class="svg-icon svg-icon-1">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)"
                      fill="currentColor" />
                    <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)"
                      fill="currentColor" />
                  </svg>
                </span>
              </div>
            </div>

            <div class="modal-body">
                <RequiredText />
                
                <Input
                    containerClass="col-12 mt-5 mb-2"
                    :name="`${id}-name`"
                    type="text"
                    :label="$t('pipelines.label.label')"
                    labelClass="form-label"
                    :inputClass="'form-control form-control-lg form-control-solid'"
                    :isRequired="true" size="lg"
                    :placeholder="$t('pipelines.label.name_placeholder')"
                    v-model="formData.name"
                />

                <InputAmount :number="formData.expected_income" :iso="defaultCurrency?.symbol" :required="true" @amount="getAmount"/>

                  <Select2
                      :parent-id="`#${id}`"
                      :is-required="true"
                      container-class="mt-auto mb-5"
                      :multiple="false"
                      :show-search="true"
                      :selectClass="'form-select form-control-lg form-select-solid'"
                      :name="`${id}-pipelines-state`"
                      :label="$t('pipelines.label.state')"
                      label-class="form-label"
                      :placeholder-text="$t('pipelines.label.select_state')"
                      v-model="formData.opportunity_state_id">
                    <option v-for="(pipelineStat, index) in activePipelines" :value="pipelineStat.id" :key="pipelineStat.id" >
                      {{ pipelineStat.name }}
                    </option>
                  </Select2>

                <div class="d-flex justify-content-between">
                  <Select2
                      :parent-id="`#${id}`"
                      :is-required="true"
                      container-class="col-12 col-md-12 mb-5"
                      :multiple="false"
                      :show-search="true"
                      :selectClass="'form-select form-control-lg form-select-solid'"
                      :name="`${id}-pipelines-prospect`"
                      :label="$t('crm.pipeline.label.opportunityContact')"
                      label-class="form-label"
                      :placeholder-text="$t('pipelines.label.select_prospect')"
                      v-model="formData.contact_id"
                  >
                      <option v-for="contact in contacts" :value="contact.id" :key="contact.id">
                        {{ contact.lastname }} {{ contact.firstname }}
                      </option>
                  </Select2>
                </div>
                <Select2
                    :is-required="false"
                    container-class="col-12 col-md-12 mb-2 mt-2"
                    :multiple="true"
                    :show-search="true"
                    :selectClass="'form-select form-control-lg form-select-solid'"
                    name="pipelines-products"
                    :label="$t('pipelines.label.products')"
                    label-class="form-label"
                    v-model="formData.products"
                    :placeholder-text="$t('pipelines.label.select_products')"
                >
                    <option v-for="(item, index) in items" :key="index" :value="item.id">{{ item.declination_title }} </option>
                </Select2>
            </div>
            <div class="modal-footer">
              <Button type="cancel" @click="closeModal" :id="id+'_cancel_btn'" btn-class="btn-sm me-3" size="7px 30px" btn-text="button.cancel"></Button>
              <Button btn-class="btn-sm me-3" type="submit" :id="id+'_submit_btn'" btn-text="button.save" size="7px 30px" :is-loading="isLoadingPipeline"></Button>
            </div>
          </div>
        </form>
      </div>
    </div>

  </template>

  <script setup>
  import { computed, ref, defineProps, onMounted } from "vue";
  import Input from "@/components/Input.vue";
  import {useStore} from "vuex";
  import RequiredText from "@/components/RequiredText"
  import InputAmount from "@/components/InputAmount.vue"
  import Select2 from "@/components/Select2.vue"
  import Button from "@/components/Button.vue"

  const store = useStore()
  const props = defineProps({
    form: {
      type: Object,
      default: {
        id: null,
        name: "",
        expected_income: "0",
        creation_date: null,
        closing_date: null,
        opportunity_source_id: "",
        contact_id: "",
        description: "",
        priority: 0,
        opportunity_state_id: '',
        products: [],
        collaborators: [],
        tags: [],
        files: []
      }
    },
    id: {
        type: String,
        required: true
    }
  })


  const emits = defineEmits(['load'])

  const formData = ref(props.form)
  const isLoadingPipeline = ref(false)

  const defaultCurrency = computed(() => store.getters['company/getCompanyCurrency'])
  const contacts = computed(() => store.getters['contacts/getContacts'])
  const activePipelines = computed(() => store.getters['pipelines/getActivePipelines'])
  const items = computed(() => store.getters['products/getProductSaleElements'])

  function closeModal(){
    $('#'+props.id).modal('toggle')
  }


  function getAmount(amount){
    formData.value.expected_income = amount
  }

  function createPipelineData() {
    isLoadingPipeline.value = true
    store.dispatch('pipelines/createPipelineSimple', formData.value)
      .then(res => {
        closeModal()
        emits('load')
        window.toastr['success'](i18n.global.t("notification.pipeline_created"))
        formData.value = {
          id: null,
          name: "",
          expected_income: "0",
          creation_date: null,
          closing_date: null,
          opportunity_source_id: "",
          contact_id: "",
          description: "",
          priority: 0,
          opportunity_state_id: '',
          products: [],
          collaborators: [],
          tags: [],
          files: []
        }
      })
      .catch((err) => {

      })
      .finally(() => isLoadingPipeline.value = false)

  }

  async function reloadContacts() {
    await store.dispatch('contacts/loadContacts')
  }

  function loadData () {
    Promise.all([
        store.dispatch('company/generalInformation'),
        store.dispatch('pipelines/loadPipelinePrioritiesEnum'),
        store.dispatch('pipelines/loadPipelineStates')
    ])
  }

  onMounted(() => {
    reloadContacts()
    loadData()
    store.dispatch('products/loadAllProductSale')
  })
  </script>

  <style scoped></style>
