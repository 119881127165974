import * as types from './mutation-types'


export const loadContacts = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.get('get/contacts/collection').then((response) => {
            commit(types.ADD_CONTACTS, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}


export const createContact = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post('store/contact', data).then((response) => {
            commit(types.ADD_CONTACT, response.data.contact)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const importContact = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post('import/contacts', data).then((response) => {

            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const exportContact = ({ commit, dispatch, state }) => {
    return new Promise((resolve, reject) => {
        window.axios.get('export/contacts', { responseType: 'blob' }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'contacts.xlsx');
            document.body.appendChild(link);
            link.click();
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const downloadContactModel = ({ commit, dispatch, state }) => {
    return new Promise((resolve, reject) => {
        window.axios.get('download-contact-model', { responseType: 'blob' }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'model-contact.xlsx');
            document.body.appendChild(link);
            link.click();
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const getContact = ({ commit, dispatch, state }, id) => {
    return new Promise((resolve, reject) => {
        window.axios.get('contact/' + id + '/details').then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const updateContact = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.patch('contact/' + data.id + '/update', data).then((response) => {
            commit(types.UPDATE_CONTACT, response.data.contact)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}


export const uploadAvatar = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post('contact/upload-avatar', data).then((response) => {
            commit(types.SET_AVATAR, { id: response.data.id, avatar: response.data.avatar })
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const loadScrollContacts = ({ commit, dispatch, state }, {search, filter, type= 'all'}) => {
    let url = `contact/scroll?filter=${filter}&search=${search}&type=${type}`
    return new Promise((resolve, reject) => {
        window.axios.get(url).then((response) => {
            commit(types.ADD_SCROLL_CONTACTS_DATA, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}


export const loadNextContacts = ({ commit, dispatch, state }, {search, filter}) => {
    let url = `contact/scroll?filter=${filter}&search=${search}&after=${state.contactScroll.map(contact => contact.id)}`
    return new Promise((resolve, reject) => {
        window.axios.get(url).then((response) => {
            commit(types.ADD_NEXT_CONTACTS, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const updateAddress = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.patch('contact/' + data.contact + '/set/address', data).then((response) => {
            //commit(types.ADD_CONTACT, response.data.contacts)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const deleteAddress = ({ commit, dispatch, state }, {contact, address}) => {
    let route = `/contact/${contact}/address/${address}/delete`
    return new Promise((resolve, reject) => {
        window.axios.delete(route).then((response) => {
            //commit(types.ADD_CONTACT, response.data.contacts)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}
export const setDefaultAddress = ({ commit, dispatch, state }, {contact, address}) => {
    let route = `/contact/${contact}/address/${address}/set/default`
    return new Promise((resolve, reject) => {
        window.axios.patch(route).then((response) => {
            //commit(types.ADD_CONTACT, response.data.contacts)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const loadContactSupplier = ({ commit, dispatch, state }) => {
    return new Promise((resolve, reject) => {
      window.axios.get('get/contact/supplier/collection').then((response) => {
          commit(types.ADD_CONTACT_SUPPLIERS, response.data.supplier)
          resolve(response)
      }).catch((err) => {
        reject(err)
      })
    })
  }

  export const loadContactStats = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.get('get/contact/'+data.id+'/invoices/stats?startDate='+data.startDate+'&endDate='+data.endDate).then((response) => {
            commit(types.CONTACTS_STATS, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const deleteContact = ({ commit }, id) => {
    return new Promise((resolve, reject) => {
        window.axios.delete('delete/' + id + '/contact').then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}
