import * as types from './mutation-types'

export const loadStatistics = ({ commit }, query) => {
  return new Promise((resolve, reject) => {
    window.axios.get(`get/crm/statistics?startDate=${query.start_date}&endDate=${query.end_date}&contact_id=${query.contact_id}&seller_id=${query.seller_id}`).then((response) => {
      commit(types.LOAD_STATISTICS, response.data)
      resolve(response)
    }).catch((err) => {
      reject(err)
    })
  })
}

export const getPipelineStats = ({commit}, data) => {
  return new Promise((resolve, reject) => {
      window.axios.get('get/crm/pipeline/statistics?startDate='+data.start_date+'&endDate='+data.end_date+'&seller_id='+data.seller_id+'&opportunity_id='+data.opportunity_id+'&status='+data.status).then((response) => {
          resolve(response)
      }).catch((err) => {
          reject(err)
      })
  })
}

export const getAppointmentStats = ({commit}, data) => {
  return new Promise((resolve, reject) => {
      window.axios.get('get/crm/appointments/statistics?startDate='+data.start_date+'&endDate='+data.end_date+'&creator_id='+data.creator_id).then((response) => {
          resolve(response)
      }).catch((err) => {
          reject(err)
      })
  })
}

export const getDownloadPipelineStatistics = ({commit}, data) => {
  return new Promise((resolve, reject) => {
      window.axios.get('download/crm/pipeline/statistics?startDate='+data.start_date+'&endDate='+data.end_date+'&seller_id='+data.seller_id+'&opportunity_id='+data.opportunity_id+'&status='+data.status, {
        responseType: 'blob'
    }).then((response) => {
          resolve(response)
      }).catch((err) => {
          reject(err)
      })
  })
}

export const getDownloadEstimateStatistics = ({commit}, data) => {
  return new Promise((resolve, reject) => {
      window.axios.get('download/crm/estimate/statistics?startDate='+data.start_date+'&endDate='+data.end_date+'&creator_id='+data.creator_id+'&contact_id='+data.contact_id, {
        responseType: 'blob'
    }).then((response) => {
          resolve(response)
      }).catch((err) => {
          reject(err)
      })
  })
}

export const getDownloadInvoiceStatistics = ({commit}, data) => {
  return new Promise((resolve, reject) => {
      window.axios.get('download/crm/invoice/statistics?startDate='+data.start_date+'&endDate='+data.end_date+'&payment_type_id='+data.payment_type_id+'&contact_id='+data.contact_id, {
        responseType: 'blob'
    }).then((response) => {
          resolve(response)
      }).catch((err) => {
          reject(err)
      })
  })
}

export const getDownloadAppointmentStatistics = ({commit}, data) => {
  return new Promise((resolve, reject) => {
      window.axios.get('download/crm/appointment/statistics?startDate='+data.start_date+'&endDate='+data.end_date+'&creator_id='+data.creator_id, {
        responseType: 'blob'
    }).then((response) => {
          resolve(response)
      }).catch((err) => {
          reject(err)
      })
  })
}