import * as types from './mutation-types'

export default {

    [types.ADD_MODULES] (state, modules) {

        if(Array.isArray(modules)){
            state.modules = modules
        }else {
            state.modules = Object.values(modules)
        }
    }
}
