<template>
  <div class="d-flex flex-column flex-md-row align-items-center gap-2 w-100">
    <div class="d-flex flex-column flex-md-row align-items-center me-auto">
      <ButtonDropdownFilter
          :options="Object.values(filterBy)"
          value-field="value"
          name-field="translate"
          v-model="category"
          class="mb-2 mb-md-0"
      />
      <ButtonDropdownFilter
        :options="Object.values(filterByType)"
        value-field="value"
        name-field="translate"
        v-model="type"
        class="mb-2 mb-md-0"
      />
      <div class="bg-white shadow justify-content-center align-items-center px-1 me-3 rounded-2 d-flex mb-2 mb-md-0">
        <a @click="changeModelViewType(modelViewType.card)"
          :class="(route.query.viewType && route.query.viewType === modelViewType.card) || !route.query.viewType ? 'active' : ''"
          class="btn btn-sm btn-outline-primary text-active-white text-hover-white btn-icon me-1">
          <i class="bi bi-grid-fill fs-4 text-primary"></i>
        </a>
        <a @click="changeModelViewType(modelViewType.list)"
          :class="route.query.viewType && route.query.viewType === modelViewType.list ? 'active' : ''"
          class="btn btn-sm btn-outline-primary text-active-white text-hover-white btn-icon">
          <i class="bi bi-list-task fs-4 text-primary"></i>
        </a>
      </div>
    </div>

    <div class="d-flex flex-column flex-md-row align-items-center">
      <Input
        v-if="showSearch" 
        container-class="me-2 mb-2 mb-md-0"
        inputClass="form-control form-control-sm"
        name="input-search"
        :isInputGroup="true"
        :placeholder="$t('label.search')"
        v-model="model"
      >
        <template #rightAddon>
          <span class="position-absolute border-0 bg-transparent end-0 top-50 translate-middle">
            <i class="fa fa-search"></i>
          </span>
        </template>
      </Input>
      <OutlineButton btn-class="me-2 btn-sm mb-2 mb-md-0" icon-class="fa fa-file-alt" type="primary" :border="true" btn-text="exports.text" v-if="$can(abilities.CONTACT_IMPORT) || $can(abilities.CONTACT_EXPORT)" @click="openImportModal"/>
      <Button btn-class="btn-sm" v-if="$can(abilities.CREATE_CONTACT)" type="new" btn-text="contacts.new_contact" @click="openModalCreateContact"/>
    </div>
  </div>
</template>

<script setup>
import {ref} from "vue";
import {useRoute, useRouter} from "vue-router";
import OutlineButton from "@/components/OutlineButton";
import Button from "@/components/Button.vue"
import Input from "@/components/Input.vue";
import {useModelViewType} from "@/composables/modelViewType";
import ButtonDropdownFilter from "@/components/ButtonDropdownFilter.vue";
import abilities from "@/composables/abilities";

const { modelViewType } = useModelViewType()
const router = useRouter()
const route = useRoute()

const model = defineModel()
const category = defineModel('category')
const type = defineModel('type')

const props = defineProps({
  title: {required: true, type: String, default: 'breadcrumbs.contact.contacts'},
  breadcrumbs: {required: false, type: Array, default: []},
  back: {required: false, type: Boolean, default: false},
  filterBy: {required: false, type: Array, default: []},
  filterByType: {required: false, type: Array, default: []},
  showSearch: {type: Boolean, default: true}
})

const filter = ref({category: "all",type: "all"})

function openImportModal() {
  $('#importContactModal').modal('toggle')
}
function openModalCreateContact() {
  $('#contactCreateModal').modal('toggle')
}
function applyFilter() {
  category.value = filter.value.category
  type.value = filter.value.type
}
function resetFilter() {
  category.value = 'all'
  type.value = 'all'
  filter.value = {
    category: 'all',
    type: 'all'
  }
}

function changeModelViewType (viewType) {
  router.push({ query: { viewType } });
}

</script>