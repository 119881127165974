import * as types from "./mutation-types";

export const createSalesTarget = ({ commit, dispatch, state }, data) => {
  return new Promise((resolve, reject) => {
    window.axios
      .post("store/salesTarget", data)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchCurrentTarget = ({ commit }) => {
  return new Promise((resolve, reject) => {
    window.axios
        .get("get/currentSalesTarget")
        .then((response) => {
          commit(types.CURRENT_TARGET, response.data);
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
  });
};

