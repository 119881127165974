import * as types from './mutation-types'

export const loadFrequencyEnum = ({commit}) => {
  return new Promise((resolve, reject) => {
      window.axios.get('frequency/enum').then((response) => {
          commit(types.LOAD_FREQUENCY_ENUM, response.data)
          resolve(response)
      }).catch((err) => {
          reject(err)
      })
  })
}

export const loadContextEnum = ({commit}) => {
  return new Promise((resolve, reject) => {
      window.axios.get('context/enum').then((response) => {
          commit(types.LOAD_CONTEXT_ENUM, response.data)
          resolve(response)
      }).catch((err) => {
          reject(err)
      })
  })
}

export const getContextFrequency = ({commit}) => {
  return new Promise((resolve, reject) => {
      window.axios.get('get/frequencyByContext').then((response) => {
          resolve(response)
      }).catch((err) => {
          reject(err)
      })
  })
}

export const configFrequency = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
      window.axios.post('store/configFrequency', data).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(err)
      })
    })
}

export const loadTemplateByModule = ({commit}) => {
  return new Promise((resolve, reject) => {
      window.axios.get('get/templateByModule/collection').then((response) => {
          commit(types.LOAD_TEMPLATES, response.data)
          resolve(response)
      }).catch((err) => {
          reject(err)
      })
  })
}

export const setDefaultTemplate = ({ commit, dispatch, state }, data) => {
  return new Promise((resolve, reject) => {
    window.axios.post('templates/'+data.id+'/setDefault', data).then((response) => {
      resolve(response)
    }).catch((err) => {
      reject(err)
    })
  })
}

export const loadSuiteApps = ({commit}) => {
  return new Promise((resolve, reject) => {
      window.axios.get('get/suit/apps').then((response) => {
          commit(types.LOAD_SUITS_APPS, response.data)
          resolve(response)
      }).catch((err) => {
          reject(err)
      })
  })
}

export const installModules = ({ commit, dispatch, state }, data) => {
  return new Promise((resolve, reject) => {
    window.axios.post('install/modules', data).then((response) => {
      resolve(response)
    }).catch((err) => {
      reject(err)
    })
  })
}