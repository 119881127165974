import { ref, computed } from 'vue'
import store from '../store'


const permissions = computed(() => store.getters['permission/permissions'])

export function useHasAbility() {
    function can(permissionName = null){
        let access = false
        if(permissionName){
            if(permissions.value.length){
                if(Array.isArray(permissionName)){
                    if(permissionName.length === 0){
                        access = true
                    }
                    for(let perm of permissionName){
                        if(!perm){
                            access = true
                        }else if(!access){
                            access = permissions.value?.indexOf(perm) !== -1
                        }
                    }
                }else {
                    access = permissions.value?.indexOf(permissionName) !== -1
                }
            }
        }else{
            access = true
        }
        return access
    }

    function accessMenu(permissionNames){
        let access = false
        if(permissionNames.length === 0){
            access = true
        }
        for(let perm of permissionNames){
            if(!perm){
                access = true
            }else if(!access){
                access = permissions.value?.indexOf(perm) !== -1
            }
        }
        return access
    }
    return { can,accessMenu }
  }