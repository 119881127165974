import * as types from './mutation-types'


export const loadPaymentTypes = ({commit}) => {
    return new Promise((resolve, reject) => {
        window.axios.get('get/paymentType/collection').then((response) => {
            commit(types.LOAD_PAYMENT_TYPES, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}


export const createPaymentTypes = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
      axios.post('store/paymentType', data).then((response) => {
        resolve(response)
        commit(types.ADD_PAYMENT_TYPE, response.data.data)
      }).catch((err) => {
        reject(err)
      })
    })
  }
  

  export const updatePaymentTypes = ({ commit, dispatch, state },  data) => {
    return new Promise((resolve, reject) => {
      axios.patch('update/' + data.id + '/paymentType', data).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(err)
      })
    })
  }


  export const deletePaymentTypes = ({ commit, dispatch, state }, id) => {
    return new Promise((resolve, reject) => {
      axios.delete('delete/' + id + '/paymentType' ).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(err)
      })
    })
  }
export const loadPaymentStatus = ({commit}) => {
    return new Promise((resolve, reject) => {
        window.axios.get('payment/status/enum').then((response) => {
            commit(types.LOAD_PAYMENT_STATUS, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const loadPaymentConditions = ({commit}) => {
    return new Promise((resolve, reject) => {
        window.axios.get('get/paymentCondition/collection').then((response) => {
            commit(types.LOAD_PAYMENT_CONDITIONS, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}


export const createPaymentConditions = ({ commit, dispatch, state }, data) => {
  return new Promise((resolve, reject) => {
    axios.post('store/paymentCondition', data).then((response) => {
      resolve(response)
      commit(types.ADD_PAYMENT_CONDITION, response.data.data)
    }).catch((err) => {
      reject(err)
    })
  })
}


export const updatePaymentConditions = ({ commit, dispatch, state },  data) => {
  return new Promise((resolve, reject) => {
    axios.patch('update/' + data.id + '/paymentCondition', data).then((response) => {
      resolve(response)
    }).catch((err) => {
      reject(err)
    })
  })
}


export const deletePaymentConditions = ({ commit, dispatch, state }, id) => {
  return new Promise((resolve, reject) => {
    axios.delete('delete/' + id + '/paymentCondition' ).then((response) => {
      resolve(response)
    }).catch((err) => {
      reject(err)
    })
  })
}
