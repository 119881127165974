import * as types from './mutation-types'

export default {


  [types.SET_CRM_CURRENT_ADVANCE_PAYMENT](state, advance) {
    state.currentAdvancePayment = advance.data
  },

}
