export const LOAD_INVOICES = 'LOAD_INVOICES'
export const SET_CURRENT_INVOICE = 'SET_CURRENT_INVOICE'
export const SET_CURRENT_RECURRING_INVOICE = 'SET_CURRENT_RECURRING_INVOICE'
export const ADD_RECURRING_INVOICE = 'ADD_RECURRING_INVOICE'
export const ADD_INVOICE = 'ADD_INVOICE'
export const DELETE_INVOICE = 'DELETE_INVOICE'
export const LOAD_INVOICE_STATUS = 'LOAD_INVOICE_STATUS'
export const LOAD_RECURRING_INVOICE_STATUS = 'LOAD_RECURRING_INVOICE_STATUS'
export const LOAD_RECURRING_INVOICE_FREQUENCY = 'LOAD_RECURRING_INVOICE_FREQUENCY'

