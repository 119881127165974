import * as types from './mutation-types'

export default {
  [types.ADD_TARGET](state, data) {
    state.salesTarget = data
  },
  [types.CURRENT_TARGET](state, data) {
    state.currentTarget = data.sale_target
  },
 
}
