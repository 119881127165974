import { ref, computed } from 'vue'
import store from '../store'


const modules = computed(() => store.getters['moduleAccess/modules'])

export function useHasModuleAccess() {
    function canAccess(moduleName = null) {
        let access = false
        if(moduleName){
            if(modules.value.length){
                if(Array.isArray(moduleName)){
                    if(moduleName.length === 0){
                        access = true
                    }
                    for(let perm of moduleName){
                        if(!perm){
                            access = true
                        }else if(!access){
                            access = modules.value?.indexOf(perm) !== -1
                        }
                    }
                }else {
                    access = modules.value?.indexOf(moduleName) !== -1
                }
            }
        }else{
            access = true
        }
        return access
    }



    function accessModule(moduleNames){
        let access = false
        if(moduleNames.length === 0){
            access = true
        }
        for(let perm of moduleNames){
            if(!access){
                access = modules.value?.indexOf(perm) !== -1
            }
        }
        return access
    }
    return { canAccess,accessModule }
  }