import i18n from "@/plugins/i18n"
import abilities from "@/composables/abilities";
import moduleAbilities from "@/composables/moduleAbilities";

const pipelineGroup = ['pipelines', "pipelines-new", "pipelines-edit", "pipelines-view","pipelines-steps", "pipelines-steps"]
const paymentGroup = ['payments', "payments-edit", "payments-create", "payments-view"]
const estimatesGroup = ["estimates", "estimates-view", "estimates-edit", "estimates-create"]
const statisticGroup = ["pipelines-chart", "estimates-chart", "invoices-chart", "appointments-chart"]
const appointmentGroup = ['appointments', "appointments-edit", "appointments-create", "appointments-view"]
const pipelineChartGroup = ['pipelines-chart']
const estimatesChartGroup = ['estimates-chart']
const appointmentsChartGroup = ['appointments-chart']

export default {
    menus:  {
        sectionTitle: "CRM",
        module : [moduleAbilities.INVOICE, moduleAbilities.ESTIMATE ,moduleAbilities.APPOINTMENT, moduleAbilities.PIPELINE],
        permission: abilities.VIEW_CRM,
        children: [
            {
                title: i18n.global.t('menu.crm.pipeline'),
                icon: 'fas fa-handshake icon-right2',
                route: 'pipelines',
                group: pipelineGroup,
                id: 'step-pipelines',
                permission: [abilities.VIEW_OPPORTUNITIES, abilities.VIEW_ALL_OPPORTUNITIES],
                children: [],
                module: [moduleAbilities.PIPELINE],
                query: {viewType: 'list'}
            },
            {
                title: i18n.global.t('menu.crm.devis'),
                icon: 'fas fa-file-alt icon-right2',
                route: 'estimates',
                group: estimatesGroup,
                id: 'step-estimates',
                permission: [abilities.VIEW_ESTIMATE, abilities.VIEW_ALL_ESTIMATE],
                children: [],
                module: [moduleAbilities.ESTIMATE],
                query: {}
            },
            {
                title: i18n.global.t('menu.crm.payment'),
                icon: 'fas fa-file-invoice-dollar icon-right1',
                route: 'payments',
                group: paymentGroup,
                id: 'step-payments',
                permission: [abilities.VIEW_PAYMENT, abilities.VIEW_ALL_PAYMENT],
                children: [],
                module: [moduleAbilities.INVOICE],
                query: {}
            },
            {
                title: i18n.global.t('menu.crm.appointments'),
                icon: 'fas fa-calendar-alt icon-right1',
                route: 'appointments',
                group: appointmentGroup,
                id: 'step-appointment',
                permission: [abilities.VIEW_APPOINTMENT, abilities.VIEW_ALL_APPOINTMENT],
                children: [],
                module: [moduleAbilities.APPOINTMENT],
                query: {}
            },
            {
                title: i18n.global.t('menu.statistics'),
                icon: 'fas fa-chart-line',
                route: 'pipelines-chart',
                group: statisticGroup,
                module: [moduleAbilities.ESTIMATE, moduleAbilities.INVOICE, moduleAbilities.PIPELINE, moduleAbilities.APPOINTMENT],
                id: 'step-crm-statistic',
                permission: [
                    abilities.VIEW_STATS_INVOICE,
                    abilities.VIEW_STATS_ESTIMATE,
                    abilities.VIEW_STATS_OPPORTUNITIES,
                    abilities.VIEW_STATS_APPOINTMENT],

                children: [
                    {
                        title: i18n.global.t('menu.crm.pipeline'),
                        icon: 'fas fa-handshake icon-right2',
                        route: 'pipelines-chart',
                        group: pipelineChartGroup,
                        id: 'step-chart-pipelines',
                        permission: [abilities.VIEW_STATS_OPPORTUNITIES],
                        children: [],
                        query: {viewType: 'list'},
                        module: [moduleAbilities.PIPELINE],
                    },
                    {
                        title: i18n.global.t('menu.crm.devis'),
                        icon: 'fas fa-file-alt icon-right2',
                        route: 'estimates-chart',
                        group: estimatesChartGroup,
                        id: 'step-chart-estimates',
                        permission: [abilities.VIEW_STATS_ESTIMATE],
                        children: [],
                        query: {},
                        module: [moduleAbilities.ESTIMATE],
                    },
                    {
                        title: i18n.global.t('menu.crm.appointments'),
                        icon: 'fas fa-calendar-alt icon-right2',
                        route: 'appointments-chart',
                        group: appointmentsChartGroup,
                        id: 'step-chart-appointments',
                        permission: [abilities.VIEW_STATS_APPOINTMENT],
                        children: [],
                        query: {},
                        module: [moduleAbilities.APPOINTMENT],
                    }
                ],
                query: {}
            }
        ]
    }
}
