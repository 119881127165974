<template>
  <div class="d-flex flex-column flex-root bg-white" style="height: 100vh!important;">
    <div class="d-flex flex-column flex-lg-row flex-column-fluid" 
          style="background: linear-gradient(rgba(0, 114, 206, 0.1), rgba(240, 120, 23, 0.1)); height: 100vh!important;">
      <div class="d-flex flex-column flex-lg-row-auto w-lg-50 h-100 d-none d-lg-flex" 
            style="position: relative;">
        <div class="d-flex flex-column justify-content-center align-items-center text-center h-100" 
              style="margin-bottom: 50px;">
          <div class="aside-logo flex-column-auto mb-10" id="kt_aside_logo">
              <img alt="Logo" src="/assets/media/logo.png" class="h-70px" />
          </div>
          <p class="pb-5 fs-3 d-none" style="color: #000000;">
            Lorem ipsum dolor sit amet. Eos eligendi optio ex amet harum vel mollitia<br> atque qui cumque quod qui omnis explicabo ut veniam eligendi. Eum illum sunt
          </p>
          <div class="d-flex justify-content-center align-items-center mt-5">
            <img alt="Logo" :src="imgs[currentStep-1]" style="height: 500px !important; width: 500px !important" />
          </div>
        </div>
      </div>

      <div class="d-flex flex-column d-lg-none flex-column-fluid justify-content-center align-items-center w-100">
        <div class="d-flex flex-column justify-content-center align-items-center text-center px-10 py-5">
          <img alt="Logo" src="/assets/media/logo.png" class="h-70px mb-4" />
          <p class="pb-5 fs-3 d-none" style="color: #000000;">
            Lorem ipsum dolor sit amet. Eos eligendi optio ex amet harum vel mollitia<br> atque qui cumque quod qui omnis explicabo ut veniam eligendi. Eum illum sunt
          </p>
          <img alt="Logo" :src="imgs[currentStep-1]" class="h-400px mb-5" />
        </div>
      </div>
      
      <div id="kt_create_account_stepper" class="stepper d-flex flex-column flex-lg-row-fluid justify-content-center align-items-center w-lg-50 mb-10 px-10" style="height: 100vh!important;">
        <div class="card shadow-sm w-100 py-5 mx-10" style="min-width: 700px; height: 95vh;">
          <div class="text-center pt-10 mb-5">
            <img alt="Logo" src="/assets/media/logo.png" class="h-50px" />
          </div>
          <h2 class="text-center mb-10">{{ $t('auth.create_account') }}</h2>
          <div class="d-flex flex-column flex-lg-row-fluid">
            <div class="d-flex flex-center flex-column flex-column-fluid">
              <div class="p-5" style="height: 100%">
                <div class="stepper-nav mb-5">
                  <div class="stepper-item current" :class="currentStep >= 1 ? 'pass' : ''" data-kt-stepper-element="nav">
                      <div class="stepper-icon">
                        <input class="form-check-input" type="checkbox" v-if="currentStep > 1" disabled :checked="currentStep > 1 ? true : false" id="flexCheckDefault" />
                        <span class="stepper-number" v-else>1</span>
                        <span class="stepper-title">{{ $t('auth.information') }}</span>
                      </div>
                      <div class="stepper-line"></div>
                  </div>
                  <div class="stepper-item" :class="currentStep >= 2 ? 'pass' : ''" data-kt-stepper-element="nav">
                    <div class="stepper-icon">
                      <input class="form-check-input" type="checkbox" v-if="currentStep > 2" disabled :checked="currentStep > 2 ? true : false" id="flexCheckDefault" />
                      <span class="stepper-number" v-else>2</span>
                        <span class="stepper-title">{{ $t('auth.representants') }}</span>
                    </div>
                    <div class="stepper-line"></div>
                  </div>
                  <div class="stepper-item" :class="currentStep >= 3 ? 'pass' : ''" data-kt-stepper-element="nav">
                      <div class="stepper-icon">
                        <input class="form-check-input" type="checkbox" v-if="currentStep > 3" disabled :checked="currentStep > 3 ? true : false" id="flexCheckDefault" />                      
                        <span class="stepper-number" v-else>3</span>
                        <span class="stepper-title">{{ $t('auth.admin') }}</span>
                      </div>
                      <div class="stepper-line"></div>
                  </div>
                  <div class="stepper-item" :class="currentStep >= 4 ? 'pass' : ''" data-kt-stepper-element="nav">
                      <div class="stepper-icon">
                        <input class="form-check-input" type="checkbox" v-if="currentStep > 4" disabled :checked="currentStep > 4 ? true : false" id="flexCheckDefault" />                     
                        <span class="stepper-number" v-else>4</span>
                        <span class="stepper-title">{{ $t('auth.checking') }}</span>
                      </div>
                      <div class="stepper-line"></div>
                  </div>
                  <div class="stepper-item" :class="currentStep >= 5 ? 'pass' : ''" data-kt-stepper-element="nav">
                      <div class="stepper-icon">
                          <input class="form-check-input" type="checkbox" v-if="currentStep > 5" disabled :checked="currentStep > 5 ? true : false" id="flexCheckDefault" />
                          <span class="stepper-number" v-else>5</span>
                          <span class="stepper-title">{{ $t('auth.end') }}</span>
                      </div>
                  </div>
                </div>
                <form class="my-auto" novalidate="novalidate" id="kt_create_account_form">
                  <div class="current" data-kt-stepper-element="content">
                    <div class="w-100">
                      <div class="mb-5">
                        <RequiredText/>
                      </div>
                      <div class="fv-row mb-5">
                        <label class="form-label mb-3 required">{{ $t('auth.name_society') }}</label>
                        <input v-model="form.society_name" type="text" class="form-control form-control-lg form-control-solid"
                          ref="society_name" name="society_name" />
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="fv-row mb-5">
                            <label class="fs-6 fw-bold form-label required">{{ $t('auth.matricule_number') }}</label>
                            <input v-model="form.matricule_number" name="matricule_number"
                              class="form-control form-control-lg form-control-solid" />
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="fv-row mb-5">
                            <label class="form-label mb-3 required">{{ $t('auth.rccm_number') }}</label>
                            <input v-model="form.rccm_number" type="text"
                              class="form-control form-control-lg form-control-solid" name="rccm_number" />
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6 mb-5">
                          <label class="fs-6 fw-bold form-label">{{ $t('auth.tva_number') }}</label>
                          <input v-model="form.tva_number" name="tva_number"
                            class="form-control form-control-lg form-control-solid" />
                        </div>
                        <div class="col-md-6 mb-5">
                          <label class="fs-6 fw-bold form-label ">{{ $t('auth.website') }}</label>
                          <input v-model="form.website" name="website"
                            class="form-control form-control-lg form-control-solid" />
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12 mb-5">
                          <div class="fv-row">
                            <label class="fs-6 fw-bold form-label required">{{ $t('auth.responsable_gender') }}</label>
                            <select name="responsable_gender" v-model="form.responsable_gender"
                              class="form-select form-select-lg form-select-solid " data-control="select2"
                              :data-placeholder="$t('auth.responsable_gender')" data-allow-clear="true">
                              <option></option>
                              <option v-for="gender in getGenders" :value="gender.value">{{ gender.fr }}</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6 mb-5">
                          <div class="fv-row">
                            <label class="fs-6 fw-bold form-label required">{{ $t('auth.responsable_lastname') }}</label>
                            <input v-model="form.responsable_lastname" name="responsable_lastname"
                                  class="form-control form-control-lg form-control-solid" />
                          </div>
                        </div>
                        <div class="col-md-6 mb-5">
                          <div class="fv-row">
                            <label class="fs-6 fw-bold form-label required">{{ $t('auth.responsable_firstname') }}</label>
                            <input v-model="form.responsable_firstname" name="responsable_firstname"
                                  class="form-control form-control-lg form-control-solid" />
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6 ">
                          <div class="fv-row">
                            <label class="fs-6 fw-bold form-label required">{{ $t('auth.responsable_email') }}</label>
                            <input v-model="form.responsable_email" type="email" name="responsable_email"
                              class="form-control form-control-lg form-control-solid" />
                          </div>
                        </div>
                        <div class="col-md-6 ">
                          <div class="fv-row">
                            <label class="fs-6 fw-bold form-label required">{{ $t('auth.responsable_phone') }}</label>
                            <vue-tel-input @country-changed="secondInputCountryChanged" :inputOptions="options"
                              @input="secondInputChanged" :autoDefaultCountry="true" id="responsable_phone"
                              class="form-control form-control-solid" name="responsable_phone"
                              v-model="form.responsable_phone"></vue-tel-input>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="" data-kt-stepper-element="content">
                    <div class="w-100">
                      <div class="w-100">
                        <div class="mb-5">
                          <RequiredText/>
                        </div>
                        <div class="row">
                          <div class="col-md-6 mb-5">
                            <div class="fv-row">
                              <label class="fs-6 fw-bold form-label required">{{ $t('auth.society_email') }}</label>
                              <input v-model="form.society_email" name="society_email"
                                class="form-control form-control-lg form-control-solid" />
                            </div>
                          </div>
                          <div class="col-md-6 mb-5">
                            <div class="fv-row">
                              <label class="fs-6 fw-bold form-label required">{{ $t('auth.society_phone') }}</label>
                              <vue-tel-input @country-changed="inputCountryChanged" :inputOptions="options"
                                @input="inputChanged" :autoDefaultCountry="true" id="society_phone"
                                class="form-control form-control-solid" name="society_phone"
                                v-model="form.society_phone"></vue-tel-input>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-12 mb-5">
                            <div class="fv-row">
                              <label class="fs-6 fw-bold form-label required">{{ $t('auth.currency') }}</label>
                              <select name="currency" v-model="form.currency_id"
                                      class="form-select form-select-lg form-select-solid " data-control="select2"
                                      :data-placeholder="$t('auth.currency_placeholder')">
                                <option></option>
                                <option v-for="currency in getCurrencies" :key="currency.id" :value="currency.id">{{ currency.name }}
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="row ">
                          <div class="col-md-6 mb-5">
                            <div class="fv-row">
                              <label class="fs-6 fw-bold form-label required">{{ $t('auth.country') }}</label>
                              <select name="country" v-model="form.country"
                                class="form-select form-select-lg form-select-solid " data-control="select2"
                                data-placeholder="Selectionner un pays" data-allow-clear="true">
                                <option></option>
                                <option v-for="country in getCountries" :key="country.id" :value="country.id">{{ country.name }}
                                </option>
                              </select>
                            </div>
                          </div>
                          <div class="col-md-6 mb-5">
                            <div class="fv-row">
                              <label class="fs-6 fw-bold form-label required">{{ $t('auth.city') }}</label>
                              <input v-model="form.city" name="city" class="form-control form-control-lg form-control-solid" />
                            </div>
                          </div>
                        </div>
                        <div class="row mb-5">
                          <div class="col-md-8">
                            <div class="fv-row">
                              <label class="fs-6 fw-bold form-label required">{{ $t('auth.address') }}</label>
                              <input v-model="form.address" name="address"
                                class="form-control form-control-lg form-control-solid" />
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="fv-row">
                              <label class="fs-6 fw-bold form-label">{{ $t('auth.zipcode') }}</label>
                              <input v-model="form.zipcode" name="zipcode"
                                class="form-control form-control-lg form-control-solid" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="" data-kt-stepper-element="content">
                    <div class="w-100">
                      <div class="w-100">
                        <div class="mb-5">
                          <RequiredText/>
                        </div>
                        <div class="row">
                          <div class="col-md-12 mb-5">
                            <div class="fv-row">
                              <label class="fs-6 fw-bold form-label required">{{ $t('auth.gender') }}</label>
                              <select name="gender" v-model="form.gender"
                                class="form-select kt_select2 form-select-lg form-select-solid" data-control="select2"
                                data-placeholder="Genre" data-allow-clear="true">
                                <option></option>
                                <option v-for="gender in getGenders" :value="gender.value">{{ gender.fr }}</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6 mb-5">
                            <div class="fv-row">
                              <label class="fs-6 fw-bold form-label required">{{ $t('auth.lastname') }}</label>
                              <input v-model="form.lastname" name="admin_lastname"
                                    class="form-control form-control-lg form-control-solid" />
                            </div>
                          </div>
                          <div class="col-md-6 mb-5">
                            <div class="fv-row">
                              <label class="fs-6 fw-bold form-label required">{{ $t('auth.firstname') }}</label>
                              <input v-model="form.firstname" name="admin_firstname"
                                    class="form-control form-control-lg form-control-solid" />
                            </div>
                          </div>
                        </div>
                        <div class="fw-row mb-5">
                          <div class="fv-row">
                            <label class="fs-6 fw-bold form-label required">{{ $t('auth.email') }}</label>
                            <input v-model="form.email" name="admin_email"
                              class="form-control form-control-lg form-control-solid" />
                          </div>
                        </div>
                        <div class="fw-row">
                          <div class="fv-row">
                            <label class="fs-6 fw-bold form-label required">{{ $t('auth.responsable_phone') }}</label>
                            <vue-tel-input @country-changed="thirstInputCountryChanged" :inputOptions="options"
                              @input="thirstInputChanged" :autoDefaultCountry="true" id="admin_phone"
                              class="form-control form-control-solid" name="admin_phone" v-model="form.phone"></vue-tel-input>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="" data-kt-stepper-element="content">
                    <div class="w-100">
                      <div class="w-100">
                        <div class="mb-0">
                          <div class="row mb-10">
                            <div class="col-md-6">
                              <h4 class="mb-5">{{ $t('auth.general_society') }}</h4>
                              <div class="fw-row mb-2">
                                <span class="fs-5 fw-bold mr-2"> {{ $t('auth.name_society') }} : </span>
                                <span class="text-gray-600"> {{ form.society_name }}</span>
                              </div>
                              <div class="fw-row mb-2">
                                <span class="fs-5 fw-bold mr-2"> {{ $t('auth.matricule_number') }} : </span>
                                <span class="text-gray-600"> {{ form.matricule_number }}</span>
                              </div>
                              <div class="fw-row mb-2">
                                <span class="fs-5 fw-bold mr-2 "> {{ $t('auth.rccm_number') }} : </span>
                                <span class="text-gray-600"> {{ form.rccm_number }}</span>
                              </div>
                              <div class="fw-row mb-2 ">
                                <span class="fs-5 fw-bold mr-2"> {{ $t('auth.tva_number') }} : </span>
                                <span class="text-gray-600"> {{ form.tva_number }}</span>
                              </div>
                              <div class="fw-row mb-2">
                                <span class="fs-5 fw-bold"> {{ $t('auth.website') }} : </span>
                                <span class="text-gray-600"> {{ form.website }}</span>
                              </div>
                              <div class="fw-row mb-2">
                                <span class="fs-5 fw-bold mr-2"> {{ $t('auth.responsable_gender') }} : </span>
                                <span class="text-gray-600"> {{ form.responsable_gender === 'male' ? 'male' : 'female' }}</span>
                              </div>
                              <div class="fw-row mb-2">
                                <span class="fs-5 fw-bold mr-2 "> {{ $t('auth.responsable_lastname') }} : </span>
                                <span class="text-gray-600"> {{ form.responsable_lastname }}</span>
                              </div>
                              <div class="fw-row mb-2">
                                <span class="fs-5 fw-bold mr-2 "> {{ $t('auth.responsable_firstname') }} : </span>
                                <span class="text-gray-600"> {{ form.responsable_firstname }}</span>
                              </div>
                              <div class="fw-row mb-2">
                                <span class="fs-5 fw-bold mr-2 "> {{ $t('auth.responsable_email') }} : </span>
                                <span class="text-gray-600"> {{ form.responsable_email }}</span>
                              </div>
                              <div class="fw-row">
                                <span class="fs-5 fw-bold mr-2"> {{ $t('auth.responsable_phone') }} : </span>
                                <span class="text-gray-600"> {{ form.responsable_phone }}</span>
                              </div>
                            </div>
                            <div class="col-md-6">
                              <h4 class="mb-5">{{ $t('auth.address_society') }}</h4>
                              <div class="fw-row mb-2">
                                <span class="fs-5 fw-bold mr-2 "> {{ $t('auth.society_email') }} : </span>
                                <span class="text-gray-600"> {{ form.society_email }}</span>
                              </div>
                              <div class="fw-row mb-2">
                                <span class="fs-5 fw-bold mr-2"> {{ $t('auth.society_phone') }} : </span>
                                <span class="text-gray-600"> {{ form.society_phone }}</span>
                              </div>
                              <div class="fw-row mb-2">
                                <span class="fs-5 fw-bold mr-2 "> {{ $t('auth.country') }} : </span>
                                <span class="text-gray-600"> {{ getCountries.find(x => x.id === form.country) ?
                                  getCountries.find(x => x.id === form.country).name : "" }}</span>
                              </div>
                              <div class="fw-row mb-2">
                                <span class="fs-5 fw-bold mr-2"> {{ $t('auth.city') }} : </span>
                                <span class="text-gray-600"> {{ form.city }}</span>
                              </div>
                              <div class="fw-row mb-2">
                                <span class="fs-5 fw-bold mr-2 "> {{ $t('auth.address') }} : </span>
                                <span class="text-gray-600"> {{ form.address }}</span>
                              </div>
                              <div class="fw-row mb-10">
                                <span class="fs-5 fw-bold mr-2"> {{ $t('auth.zipcode') }} : </span>
                                <span class="text-gray-600"> {{ form.zipcode }}</span>
                              </div>
                              <div class="fw-row mb-2">
                                <span class="fs-5 fw-bold mr-2 "> {{ $t('auth.currency') }} : </span>
                                <span class="text-gray-600"> {{ getCurrencies.find(x => x.id === form.currency_id) ?
                                    getCurrencies.find(x => x.id === form.currency_id).name : "" }}</span>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-6">
                              <h4 class="mb-5">{{ $t('auth.creation_admin') }}</h4>
                              <div class="fw-row mb-2">
                                <span class="fs-5 fw-bold mr-2 "> {{ $t('auth.gender') }} : </span>
                                <span class="text-gray-600"> {{ form.gender === 'male' ? 'Homme' : 'Femme' }}</span>
                              </div>
                              <div class="fw-row mb-2">
                                <span class="fs-5 fw-bold mr-2"> {{ $t('auth.lastname') }} : </span>
                                <span class="text-gray-600"> {{ form.lastname }}</span>
                              </div>
                              <div class="fw-row mb-2">
                                <span class="fs-5 fw-bold mr-2"> {{ $t('auth.firstname') }} : </span>
                                <span class="text-gray-600"> {{ form.firstname }}</span>
                              </div>
                              <div class="fw-row mb-2">
                                <span class="fs-5 fw-bold mr-2 "> {{ $t('auth.email') }} : </span>
                                <span class="text-gray-600"> {{ form.email }}</span>
                              </div>
                              <div class="fw-row mb-2">
                                <span class="fs-5 fw-bold mr-2"> {{ $t('auth.phone') }} : </span>
                                <span class="text-gray-600"> {{ form.phone }}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="" data-kt-stepper-element="content">
                    <div class="w-100">
                      <div class="w-100">
                        <div class="mb-0">
                          <div class="notice d-flex bg-light-success rounded border-success border border-dashed p-6">
                            <span class="svg-icon svg-icon-2tx svg-icon-success me-4">
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor" />
                                <rect x="11" y="14" width="7" height="2" rx="1" transform="rotate(-90 11 14)"
                                  fill="currentColor" />
                                <rect x="11" y="17" width="2" height="2" rx="1" transform="rotate(-90 11 17)"
                                  fill="currentColor" />
                              </svg>
                            </span>
                            <div class="d-flex flex-stack flex-grow-1">
                              <div class="fw-bold">
                                <h4 class="text-gray-900 fw-bolder">{{ $t('inscription_welcome') }}</h4>
                                <div class="fs-6 text-gray-700">
                                  {{ $t('inscription_check') }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex flex-stack my-10">
                    <div class="mr-2">
                      <button type="button" class="btn btn-lg btn-light-primary me-3" data-kt-stepper-action="previous">
                        {{ $t('previous') }}
                      </button>
                    </div>
                    <div>
                      <button type="button" class="btn btn-lg btn-primary" data-kt-stepper-action="submit">
                        <span class="indicator-label">{{ $t('send') }}</span>
                        <span class="indicator-progress">{{ $t('loading') }}
                          <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                      </button>
                      <button type="button" class="btn btn-lg btn-primary" data-kt-stepper-action="next">Continue</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="fixed-top-right p-5">
        <LangDropdown />
      </div> -->
    </div>
  </div>
</template>
  
<script>
  import { VueTelInput } from 'vue-tel-input'
  import 'vue-tel-input/dist/vue-tel-input.css';
  import AttachFile from '@/components/AttachFile.vue'
  import { mapActions } from "vuex";
  import { appVersion, getTenantId, removeProtocol, getUrlProtocol } from "@/mixins/Helpers";
  import LangDropdown from "@/components/LangDropdown";
  import RequiredText from "@/components/RequiredText";

  export default {
    name: "CreateAccount",
    data() {
      return {
        backUrl: process.env.VUE_APP_PORT ? process.env.VUE_APP_HOST + ':' + process.env.VUE_APP_PORT : process.env.VUE_APP_HOST,
        form: {
          society_name: "",
          society_email: "",
          society_phone: "",
          society_phone_code: "",
          responsable_phone_code: "",
          ifu_file: [],
          rccm_file: [],
          address_file: [],
          name_society: "",
          matricule_number: "",
          rccm_number: "",
          responsable_gender: "",
          responsable_lastname: "",
          responsable_firstname: "",
          responsable_email: "",
          responsable_phone: "",
          address: "",
          city: null,
          zipcode: "",
          tva_number: "",
          website: "",
          country: "",
          currency_id: "",
          gender: "",
          lastname: "",
          firstname: "",
          phone: "",
          phone_code: "",
          condition: true
        },
        getGenders:[],
        getCountries: [],
        getCurrencies: [],
        dataInvoice: {
          ifu: null,
          tokenApi: null,
          defaultTaxe: null
        },
        isBeninCountry: false,
        options: {
          placeholder: "Ex : 97 97 00 00",
          showDialCode: true
        },
        langs: {
          en: {
            'img': '/assets/media/united-states.svg',
            'title': 'header.language.english',
            'code': 'en'
          },
          fr: {
            'img': '/assets/media/france.svg',
            'title': 'header.language.french',
            'code': 'fr'
          },
        },
        currentStep: 1,
        imgs: ['/assets/media/info.png', '/assets/media/representants.png', '/assets/media/admin.png', '/assets/media/check.png', '/assets/media/check.png', '/assets/media/check.png']
      }
  
    },
    components: {
      RequiredText,
      LangDropdown,
      VueTelInput,
      AttachFile
    },
  
    computed: {
      currentLanguage() {
        return this.langs[this.$i18n.locale]
      },
      versionApp() {
        return appVersion.value
      }
    },
    async mounted() {
  
      this.initSelect2()
      await this.loadCountries()
      await this.loadCurrencies()
      await this.loadGenders()
      this.validateCreateAccountForm()
    },
    methods: {
      ...mapActions('auth', [
        'createAccount',
      ]),
  
      changeLang(lang) {
        this.$i18n.locale = lang.code
        localStorage.setItem('locale', lang.code)
      },
  
      async loadCountries() {
        window.axiosDefault.get('get/countries').then((response) => {
          this.getCountries = response.data.countries
        }).catch((err) => {
  
        })
      },
  
      async loadCurrencies() {
        window.axiosDefault.get('get/currencies/collection').then((response) => {
          this.getCurrencies = response.data.currencies
        }).catch((err) => {
  
        })
      },
  
      async loadGenders() {
        window.axiosDefault.get('get/gender/enum').then((response) => {
          this.getGenders = response.data
        }).catch((err) => {
  
        })
      },
  
      validateCreateAccountForm() {
        var e, t, i, o, s, r, a = [];
        (e = document.querySelector("#kt_modal_create_account")) && new bootstrap.Modal(e), t = document.querySelector("#kt_create_account_stepper"), i = t.querySelector("#kt_create_account_form"), o = t.querySelector('[data-kt-stepper-action="submit"]'), s = t.querySelector('[data-kt-stepper-action="next"]'), (r = new KTStepper(t)).on("kt.stepper.changed", ((e) => {
          4 === r.getCurrentStepIndex() ? (o.classList.remove("d-none"), o.classList.add("d-inline-block"), s.classList.add("d-none")) : 5 === r.getCurrentStepIndex() ? (o.classList.add("d-none"), s.classList.add("d-none")) : (o.classList.remove("d-inline-block"), o.classList.remove("d-none"), s.classList.remove("d-none"))
        })), r?.on("kt.stepper.next", ((e) => {
          var t = a[e.getCurrentStepIndex() - 1];
          t ? t.validate().then(((t) => {
            "Valid" == t ? (e.goNext(), KTUtil.scrollTop(), this.currentStep += 1) : Swal.fire({
              text: i18n.global.t('formValidation.error_alert'),
              icon: "error",
              buttonsStyling: !1,
              confirmButtonText: "Ok!",
              customClass: { confirmButton: "btn btn-light" },
              confirmButtonColor: '#0072CE',
              cancelButtonColor: '#d33',
  
            }).then((() => {
              KTUtil.scrollTop()
            }))
          })) : (e.goNext(), KTUtil.scrollTop())
        })), r?.on("kt.stepper.previous", ((e) => {
          this.currentStep -= 1
          e.goPrevious(), KTUtil.scrollTop()
        })),
          a.push(FormValidation.formValidation(i, {
            fields: {
              society_name: {
                validators: {
                  notEmpty: {
                    message: i18n.global.t('formValidation.field_required')
                  }
                }
              },
              matricule_number: {
                validators: {
                  notEmpty: {
                    message: i18n.global.t('formValidation.field_required')
                  }
                }
              },
              rccm_number: {
                validators: {
                  notEmpty: {
                    message: i18n.global.t('formValidation.field_required')
                  }
                }
              },
              responsable_gender: {
                validators: {
                  notEmpty: {
                    message: i18n.global.t('formValidation.field_required')
                  }
                }
              },
              responsable_lastname: {
                validators: {
                  notEmpty: {
                    message: i18n.global.t('formValidation.field_required')
                  }
                }
              },
              responsable_firstname: {
                validators: {
                  notEmpty: {
                    message: i18n.global.t('formValidation.field_required')
                  }
                }
              },
              responsable_email: {
                validators: {
                  notEmpty: {
                    message: i18n.global.t('formValidation.field_required')
                  },
                  emailAddress: {
                    message: i18n.global.t('formValidation.field_must_be_email')
                  }
                }
              },
              responsable_phone: {
                validators: {
                  callback: {
                    message: i18n.global.t('formValidation.phone_number_not_valid'),
                    callback: (value, validator, $field) => {
                      let number = this.form.responsable_phone.replace(this.form.responsable_phone_code, '')
                      return number.trim().length ? true : false
                    }
                  },
                }
              }
            },
            plugins: {
              trigger: new FormValidation.plugins.Trigger,
              bootstrap: new FormValidation.plugins.Bootstrap5({
                rowSelector: ".fv-row",
                eleInvalidClass: "",
                eleValidClass: ""
              }),
            }
          })),
          a.push(FormValidation.formValidation(i, {
            fields: {
              society_email: {
                validators: {
                  notEmpty: {
                    message: i18n.global.t('formValidation.field_required')
                  },
                  emailAddress: {
                    message: i18n.global.t('formValidation.field_must_be_email')
                  }
                }
              },
              society_phone: {
                validators: {
                  callback: {
                    message: i18n.global.t('formValidation.phone_number_not_valid'),
                    callback: (value, validator, $field) => {
                      let number = this.form.society_phone.replace(this.form.society_phone_code, '')
                      return number.trim().length ? true : false
                    }
                  },
                }
              },
              country: {
                validators: {
                  notEmpty: {
                    message: i18n.global.t('formValidation.field_required')
                  },
                }
              },
              city: {
                validators: {
                  notEmpty: {
                    message: i18n.global.t('formValidation.field_required')
                  },
                }
              },
              currency_id: {
                validators: {
                  notEmpty: {
                    message: i18n.global.t('formValidation.field_required')
                  },
                }
              },
              address: {
                validators: {
                  notEmpty: {
                    message: i18n.global.t('formValidation.field_required')
                  },
                }
              },
            },
            plugins: {
              trigger: new FormValidation.plugins.Trigger,
              bootstrap: new FormValidation.plugins.Bootstrap5({
                rowSelector: ".fv-row",
                eleInvalidClass: "",
                eleValidClass: ""
              })
            }
          })),
          a.push(FormValidation.formValidation(i, {
            fields: {
              gender: {
                validators: {
                  notEmpty: {
                    message: i18n.global.t('formValidation.field_required')
                  }
                }
              },
              admin_lastname: {
                validators: {
                  notEmpty: {
                    message: i18n.global.t('formValidation.field_required')
                  }
                }
              },
              admin_firstname: {
                validators: {
                  notEmpty: {
                    message: i18n.global.t('formValidation.field_required')
                  }
                }
              },
              admin_email: {
                validators: {
                  notEmpty: {
                    message: i18n.global.t('formValidation.field_required')
                  },
                  emailAddress: {
                    message: i18n.global.t('formValidation.field_must_be_email')
                  }
                }
              },
              admin_phone: {
                validators: {
                  callback: {
                    message: i18n.global.t('formValidation.phone_number_not_valid'),
                    callback: (value, validator, $field) => {
                      let number = this.form.phone.replace(this.form.phone_code, '')
                      return number.trim().length ? true : false
                    }
                  },
                }
              },
            },
            plugins: {
              trigger: new FormValidation.plugins.Trigger,
              bootstrap: new FormValidation.plugins.Bootstrap5({
                rowSelector: ".fv-row",
                eleInvalidClass: "",
                eleValidClass: ""
              })
            }
          })),
          o.addEventListener("click", ((e) => {
            a[2].validate().then((async (t) => {
              if ("Valid" == t) {
                e.preventDefault()
  
                if (!this.form.condition) {
                  window.toastr.error("Veuillez accepter les conditions générales d'utilisation")
                } else {
                  o.disabled = !0
                  this.createAccount(this.form).then((res) => {
                    window.toastr.success(res.data.message)
                    o.removeAttribute("data-kt-indicator")
                    o.disabled = !1
                    this.currentStep += 1
                    r.goNext()
                    //this.$router.push('/login')
  
                  }).catch(() => {
                    o.removeAttribute("data-kt-indicator")
                    o.disabled = !1
                    KTUtil.scrollTop()
                  })
                }
  
              } else {
                Swal.fire({
                  text: i18n.global.t('formValidation.error_alert'),
                  icon: "error",
                  buttonsStyling: !1,
                  confirmButtonText: i18n.global.t('formValidation.ok'),
                  customClass: { confirmButton: "btn ", cancelButton: "btn text-white" },
                  confirmButtonColor: '#0072CE',
                  cancelButtonColor: '#d33',
                }).then((() => {
                  KTUtil.scrollTop()
                }))
              }
  
            }))
          }))
      },
  
      inputChanged(number, phoneObject) {
        let errorDiv = document.querySelector('[data-field="responsable_phone"][data-field="responsable_phone"][data-validator="callback"]')
        if (errorDiv) {
          errorDiv.remove()
        }
  
        if (phoneObject) {
          this.form.society_phone = phoneObject.nationalNumber
          this.form.society_phone_code = '+' + phoneObject.countryCallingCode
        }
      },
  
      secondInputChanged(number, phoneObject) {
        let errorDiv = document.querySelector('[data-field="society_phone"][data-field="society_phone"][data-validator="callback"]')
        if (errorDiv) {
          errorDiv.remove()
        }
        if (phoneObject) {
          this.form.responsable_phone = phoneObject.nationalNumber
          this.form.responsable_phone_code = '+' + phoneObject.countryCallingCode
        }
      },
  
      inputCountryChanged(country) {
        this.form.society_phone_code = '+' + country.dialCode
      },
  
      secondInputCountryChanged(country) {
        this.form.responsable_phone_code = '+' + country.dialCode
      },
  
      thirstInputCountryChanged(country) {
        this.form.phone_code = '+' + country.dialCode
      },
  
      thirstInputChanged(number, phoneObject) {
        let errorDiv = document.querySelector('[data-field="admin_phone"][data-field="admin_phone"][data-validator="callback"]')
        if (errorDiv) {
          errorDiv.remove()
        }
  
        if (phoneObject) {
          this.form.phone = phoneObject.nationalNumber
          this.form.phone_code = '+' + phoneObject.countryCallingCode
        }
      },
  
      initSelect2() {
        $("select[data-control='select2']").select2()
        $("select[data-control='select2']").change((e) => {
          switch (e.target.name) {
            case 'city':
              this.form.city = e.target.value
              break
            case 'country':
              this.form.country = e.target.value
              const countryTrouve = this.getCountries.find(country => country.id === e.target.value)
              this.isBeninCountry = countryTrouve?.iso_alpha2 == 'BJ' ? true : false
              break
            case 'currency':
              this.form.currency_id = e.target.value
              break
            case 'gender':
              this.form.gender = e.target.value
              break
            case 'type_taxe':
              this.dataInvoice.defaultTaxe = e.target.value
              break
            case 'responsable_gender':
              this.form.responsable_gender = e.target.value
              break
  
          }
        })
      }
    }
  
  }
</script>
  
<style scoped>
    .stepper-nav {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }

    .stepper-item {
        display: flex;
        align-items: center;
        position: relative;
        text-align: center;
        flex: 1;
    }

    .stepper-icon {
        background-color: #fff;
        border: 1px solid rgba(204, 207, 211, 1); 
        border-radius: 8px;
        padding: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
    }

    .pass .stepper-icon {
        background-color: #fff;
        border: 1px solid #0072CE; 
        border-radius: 8px;
        padding: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
    }

    .stepper-number {
        background-color: rgba(204, 207, 211, 1);
        padding: 0px 5px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .pass .stepper-number {
        background-color: #0072CE;
        color: white;
        padding: 0px 5px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .stepper-title {
        font-weight: 400;
    }

    .pass .stepper-title{
        font-weight: bold;
        color: #0072CE !important;
    }

    .stepper-line {
        height: 2px;
        background-color: #0072CE;
        flex: 1;
        margin: 0 10px;
    }

    .stepper-item:last-child .stepper-line {
        display: none;
    }

    .stepper-title{
      font-size: 12px;
    }

</style>
  