<template>
  <div class=" px-5 mx-7 pt-5">
    <Card :title="$t('contacts.list')" containerClass="mb-5">
      <template #cardBody>
        <div class="row">
          <table class="table align-middle table-row-bordered fs-6 gy-3 gx-4" id="contacts_datatable">
            <thead>
              <tr class="fw-bold fs-6" style="background-color: #F2F3F4;">
                <th class="min-w-100px text-start">{{ $t('contacts.label.fullname') }}</th>
                <th class="min-w-150px text-start">{{ $t('contacts.label.type') }}</th>
                <th class="min-w-150px text-start">{{ $t('contacts.label.status') }}</th>
                <th class="min-w-150px text-start">{{ $t('contacts.label.phone') }}</th>
                <th class="min-w-150px text-start">{{ $t('contacts.label.email') }}</th>
                <th class="w-50px text-start">{{ $t('label.action') }}</th>
              </tr>
            </thead>
          </table>
        </div>
      </template>
    </Card>
  </div>
</template>

<script setup>
import {defineProps, onUpdated, onMounted, ref, watch, inject} from "vue";
import i18n from "@/plugins/i18n";
import {useRouter} from "vue-router";
import {useStore} from "vuex";
import Ls from "@/services/ls";
import {useHasAbility} from "@/composables/hasAbility";
import {useLocales} from "@/composables/locales";
import { BASE_URL } from "@/services/api";
import Card from "@/Modules/CRM/components/Card";
import {Theme} from "@/composables/theming";
import abilities from "@/composables/abilities";
import {useSwal} from "@/composables/useSwal";

const emitter = inject('emitter')
const store = useStore()
const router = useRouter()
const { can } = useHasAbility()
const {locale, datatableLocaleUrl} = useLocales()
const {showSwal} = useSwal()

const props = defineProps({
  filter: {
    type: String,
    default: ''
  },
  type: {
    type: String,
    default: ''
  }
})

const table = ref(null)

watch(() => props.filter, (value) => {
  if(value){
    table.value.ajax.reload()
  }
})

watch(() => props.type, (value) => {
  if(value){
    table.value.ajax.reload()
  }
})

function fetchDatas() {
  const AUTH_TOKEN = Ls.get('auth.token')

  table.value = $("#contacts_datatable").DataTable({
    destroy: true,
    responsive: !0,
    searchDelay: 500,
    processing: !0,
    serverSide: !0,
    pageLength: Ls.get('pageLength') ?? 5,
    lengthMenu: [5, 10, 25, 50, 75, 100 ],
    order: [[ 1, 'asc']],
    ajax: {
      url: BASE_URL + `/contacts/datatables?filter=${props.filter}&type=${props.type}`,
      type: "GET",
      beforeSend: function (request) {
        request.setRequestHeader("Authorization", "Bearer " + AUTH_TOKEN);
      }
    },
    columns: [
      { data: "fullname" }, { data: "contactType" }, { data: "customer" }, { data: "phone" }, { data: "email" }, { data: "Actions", responsivePriority: -1 }
    ],
    columnDefs: [
    {
      targets: -6,
      orderable: false,
      className: "dt-center text-start clickable" ,
      render: function (t, a, e, l) {
        return '<span data-id="'+ e.id +'" class="d-flex text-start"><img class="rounded-circle me-5" style="width: 30px !important; height: 30px !important; border-radius: 10px; object-fit: cover; object-position: center;" src="'+ e.avatar_url +'" /><span class="mt-3">' +  t + "</span></span>";
      },
    },
    {
        width: '25%',
        targets: -5,
        order: "desc",
        className: "dt-center text-start clickable",
        render: function (t, a, e, l) {
            let iconHTML = '';
            if(e.contactType == 'particular'){
                iconHTML = '<div class="icon-overlay rounded-circle me-2 d-flex align-items-center justify-content-center" style="background-color: #F07817; width: 25px; height: 25px;"><i class="fas fa-user" style="color: white;"></i></div>';
            } else {
                iconHTML = '<div class="icon-overlay rounded-circle me-2 d-flex align-items-center justify-content-center" style="background-color: #F3DC1F; width: 25px; height: 25px;"><i class="fas fa-building" style="color: #000;"></i></div>';
            }
            return '<span data-id="'+ e.id +'" class="d-flex align-items-start justify-content-start text-start">' + iconHTML + '<span class="mt-2">' + (e.contactType == 'particular' ? i18n.global.t('contacts.type') : i18n.global.t('contacts.detail.society')) + '</span></span>';
        },
    },
      {
        targets: -4,
        order: "desc",
        orderable: false,
        className: "dt-center text-start clickable" ,
        render: function (t, a, e, l) {
          if(e.customer && !e.supplier){
            return '<span data-id="'+ e.id +'" class="badge badge-light-success">' + i18n.global.t('contacts.customer') + "</span>";
          }else if(!e.customer && e.supplier){
            return '<span data-id="'+ e.id +'" class="badge badge-light-warning">' + i18n.global.t('contacts.supplier') + "</span>";
          }else if(e.customer && e.supplier){
            return '<span data-id="'+ e.id +'" class="badge badge-light-primary">' + i18n.global.t('contacts.customer') + ' | ' + i18n.global.t('contacts.supplier') + "</span>";
          }else{
            return '<span data-id="'+ e.id +'"></span>';
          }
        },
      },

      {
        width:'25%',
        targets: -3,
        orderable: true,
        className: "dt-center text-start clickable" ,
        render: function (t, a, e, l) {
          const phone = JSON.parse(t)[0]
          if(phone){
            return '<span data-id="'+ e.id +'">' + phone + "</span>";
          }else{
            return '<span data-id="'+ e.id +'"></span>';
          }
        },
      },

      {
        targets: -2,
        orderable: true,
        className: "dt-center text-start clickable" ,
        render: function (t, a, e, l) {
          if(t !== null){
            return '<span data-id="'+ e.id +'">' + t + "</span>";
          }else{
            return '<span data-id="'+ e.id +'"></span>';
          }
        },
      },
      {
        targets: -1,
        "className": "dt-center text-start",
        title: "Actions",
        orderable: !1,
        render: (t, a, e, l) => {
          let render=""
          if(can(abilities.VIEW_CONTACT)){
            render += `<a href="javascript:;" data-row="${l.row}" data-id="${e.id}" data-name="${e.name}" class="btn btn-sm btn-clean btn-icon show_btn" title="${i18n.global.t('button.edit')}"><i data-row="${l.row}" data-id="${e.id}" data-name="${e.name}" class="fas fa-edit fs-2"  style="color: ${Theme.gray}" ></i></a>`;
          }
          /* if(can(abilities.DELETE_CONTACT)){
            render += `<a href="javascript:;" data-row="${l.row}" data-id="${e.id}" data-name="${e.name}" class="btn btn-sm btn-clean btn-icon delete_btn" title="${i18n.global.t('button.delete')}"><i data-row="${l.row}" data-id="${e.id}" data-name="${e.name}" class="fas fa-trash fs-2"  style="color: ${Theme.gray}" ></i></a>`;
          } */
          return render
        },
      },

    ],
    language: {
      url: datatableLocaleUrl.value,
    }
  });

  $(document).on("click", "#contacts_datatable .show_btn", (e) => {
    router.push({ name: 'contact-view-path', params: { id: e.target.getAttribute('data-id') } })
  });
  $(document).on("click", "#contacts_datatable .delete_btn", (e) => {
      let id = e.target.getAttribute('data-id')
      deleteContact(id)
  });

}

async function deleteContact(id) {
  showSwal(
      i18n.global.t('swal.title'),
      i18n.global.t('swal.contacts_deleted')
  ).then( async (result) => {
      if (result.value) {
          store.dispatch('contacts/deleteContact', id).then((res) => {
              table.value.ajax.reload()
              window.toastr['success'](i18n.global.t('notification.contact_deleted'))
          }).catch(() => {

          })
      }
  })
}

onMounted(() => {
  store.dispatch('company/generalInformation')
  fetchDatas()
  emitter?.on('reload-contact-page',fetchDatas)
})

onUpdated(() => {
  fetchDatas()
})

</script>

<style scoped>
.icon-overlay {
  position: absolute;
  color: white;
  border-radius: 50%;
  padding: 8px;
  font-size: 24px;
}
</style>

