import * as types from './mutation-types'
import {LOAD_RECURRING_INVOICE_FREQUENCY, SET_CURRENT_INVOICE} from "./mutation-types";


export const loadInvoices = ({commit}) => {
    return new Promise((resolve, reject) => {
        window.axios.get('get/notPaid/collection').then((response) => {
            commit(types.LOAD_INVOICES, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}


export const getInvoiceById = ({commit}, id) => {
    return new Promise((resolve, reject) => {
        window.axios.get('get/invoice/'+id).then((response) => {
            commit(types.SET_CURRENT_INVOICE, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const createInvoice = ({commit}, data) => {

    return new Promise((resolve, reject) => {
        window.axios.post('store/invoice', data).then((response) => {
            resolve(response)
            commit(types.ADD_INVOICE, response.data.invoice)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const updateInvoice = ({commit}, data) => {
    return new Promise((resolve, reject) => {
        window.axios.patch('update/' + data.id + '/invoice', data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const deleteInvoice = ({commit}, id) => {
    return new Promise((resolve, reject) => {
        window.axios.delete('delete/' + id + '/invoice').then((response) => {
            commit(types.DELETE_INVOICE, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const payInvoice = ({commit}, data) => {
    let formData = new FormData();
    data.files?.forEach((file, index) => {
        formData.append(`files[${index}]`, file);
    });
    formData.append('payment_date', data.payment_date)
    formData.append('invoice_id', data.invoice_id)
    formData.append('type', data.type)
    formData.append('percentage', data.type == "amount" ? 0 : data.value)
    formData.append('amount', data.type == "amount" ? data.value : 0)
    formData.append('payment_type_id', data.payment_type_id)
    formData.append('_method', 'POST')

    return new Promise((resolve, reject) => {
        window.axios.post('store/invoice/payment', formData, {headers: {'Content-type': 'multipart/form-data'}}).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const sendInvoice = ({commit}, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post('send/invoice/'+ data.invoiceId + '/email', data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const downloadInvoice = ({commit}, id) => {
    return new Promise((resolve, reject) => {
        window.axios.get(`get/invoice/pdf/${id}`, {
            responseType: 'blob'
        }).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}



export const loadInvoiceStatus = ({commit}) => {
    return new Promise((resolve, reject) => {
        window.axios.get('invoice/status/enum').then((response) => {
            commit(types.LOAD_INVOICE_STATUS, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const saveInvoiceCreditNote = ({ commit, dispatch, state }, id) => {
    return new Promise((resolve, reject) => {
        window.axios.post(`store/invoice/${id}/credit/note`).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const acomptePayInvoice = ({commit}, data) => {
    let formData = new FormData();
    data.files?.forEach((file, index) => {
        formData.append(`files[${index}]`, file);
    });
    formData.append('payment_date', data.payment_date)
    formData.append('invoice_id', data.invoice_id)
    formData.append('type', data.type)
    formData.append('percentage', data.type == "amount" ? 0 : data.value)
    formData.append('amount', data.type == "amount" ? data.value : 0)
    formData.append('payment_type_id', data.payment_type_id)
    formData.append('status', data.status)
    formData.append('due_date', data.due_date)
    formData.append('_method', 'POST')

    return new Promise((resolve, reject) => {
        window.axios.post('store/acompte/invoice/payment', formData, {headers: {'Content-type': 'multipart/form-data'}}).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const cancelInvoiceMail = ({commit}, id) => {
    return new Promise((resolve, reject) => {
        window.axios.post('cancel-email/invoice/'+ id + '/email').then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const validatedInvoice = ({commit}, data) => {
    return new Promise((resolve, reject) => {
        window.axios.patch('set/invoice/' + data.id + '/validated', data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const archivedInvoice = ({commit}, data) => {
    return new Promise((resolve, reject) => {
        window.axios.patch('set/invoice/' + data.id + '/archived', data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const getInvoiceStats = ({commit}, data) => {
    return new Promise((resolve, reject) => {
        window.axios.get('get/crm/invoice/statistics?startDate='+data.start_date+'&endDate='+data.end_date+'&payment_type_id='+data.payment_type_id+'&contact_id='+data.contact_id).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const storeAdvanceInvoice = ({commit}, data) => {
    let formData = new FormData();
    data.files?.forEach((file, index) => {
        formData.append(`files[${index}]`, file);
    });
    formData.append('advancePayment', data.advancePayment)
    formData.append('payment_date', data.payment_date)
    formData.append('date', data.date)
    formData.append('invoice_id', data.invoice_id)
    formData.append('type', data.type)
    formData.append('percentage', data.type == "amount" ? 0 : data.value)
    formData.append('amount', data.type == "amount" ? data.value : 0)
    formData.append('payment_type_id', data.payment_type_id)
    formData.append('status', data.status)
    formData.append('due_date', data.due_date)
    formData.append('_method', 'POST')

    return new Promise((resolve, reject) => {
        window.axios.post('store/invoice/advance', formData, {headers: {'Content-type': 'multipart/form-data'}}).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const loadRecurringInvoiceFrequency = ({commit}) => {
    return new Promise((resolve, reject) => {
        window.axios.get('recurring/invoice/frequency/enum').then((response) => {
            commit(types.LOAD_RECURRING_INVOICE_FREQUENCY, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const createRecurrentInvoice = ({commit}, data) => {

    return new Promise((resolve, reject) => {
        window.axios.post('store/recurring/invoice', data).then((response) => {
            resolve(response)
            commit(types.ADD_RECURRING_INVOICE, response.data.recurringInvoices)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const getRecurrentInvoiceById = ({commit}, id) => {
    return new Promise((resolve, reject) => {
        window.axios.get('show/recurring/invoice/'+id).then((response) => {
            commit(types.SET_CURRENT_RECURRING_INVOICE, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const updateRecurrentInvoice = ({commit}, data) => {
    return new Promise((resolve, reject) => {
        window.axios.patch('update/recurring/' + data.id + '/invoice', data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}
export const loadRecurrentInvoiceStatus = ({commit}) => {
    return new Promise((resolve, reject) => {
        window.axios.get('recurring/invoice/status/enum').then((response) => {
            commit(types.LOAD_RECURRING_INVOICE_STATUS, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

