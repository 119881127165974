<template>
  <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
    <div v-show="isReady">
      <div class="post d-flex flex-column-fluid" id="kt_post">
        <div id="kt_content_container" class="contactList">
          <div id="contenu" v-if="getScrollContacts.length">
            <div class="row g-6 g-xl-9">
              <ContactCard  v-for="contact in getScrollContacts" :contact="contact"></ContactCard>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!getScrollContacts.length" class="row text-center justify-content-center align-items-center"
           style="height :60vh !important;width: 100%!important;">
        <div class=" text-center ">
          <div class="pt-8 ">
            <img src="/assets/media/empty.png" alt=""
                class="mw-100 h-150px h-sm-225px" />
          </div>
          <h2 class="fs-2x fw-bolder mb-0">{{ $t('contacts.no_contact_found') }}</h2>
          <p class="text-gray-400 fs-4 fw-bold py-7">{{ $t('button.clic') }}<br /></p>
          <Button btn-class="text-center  btn-sm" v-if="$can('contact_create')" type="new" btn-text="contacts.new_contact" @click="openModalCreateContact"/>

        </div>
      </div>
    </div>
    <div v-show="!isReady">
      <div class="row loader text-center">
        <div style="text-align: center" class="spinner"></div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {computed, defineProps, inject, nextTick, onMounted, ref, watch} from "vue";
import {useStore} from "vuex";
import ContactCard from "@/components/contact/ContactCard";
import Button from "@/components/Button.vue"


const props = defineProps({
  filter: {
    type: String,
    default: ''
  },
  type: {
    type: String,
    default: ''
  },
  search: {
    type: String,
    default: ''
  }
})

const emitter = inject('emitter')

const store = useStore()
const search = ref('')
const $contacts = ref('')
const filter = ref('all')
const contactType = ref('all')

const isReady = ref(false)



const getScrollContacts = computed(() => store.getters['contacts/getScrollContacts'])
const getScrollContactsCount = computed(() => store.getters['contacts/getScrollContactsCount'])


watch(() => props.filter, (value) => {
  if(value){
    filter.value = value
  }
})

watch(() => props.type, (value) => {
  if(value){
    contactType.value = value
  }
})

watch(() => props.search, (value) => {
  search.value = value
})

onMounted(async() => {
 isReady.value = false

 loadCountries()
 loadCurrencies()
 await chargeContacts().then(() => {
   $contacts.value = document.getElementById('contenu')
 })
 .finally(()=>{ isReady.value = true})
  emitter?.on('reload-contact-page',chargeContacts)
})

watch([() =>  filter.value,() =>  contactType.value,() =>  search.value], async (value) => {
 isReady.value = false
  await chargeContacts().finally(() => {
       isReady.value = true
     })
})

const loadCountries = async () => {
 try {
   await store.dispatch('countries/loadCountries')
 }catch (e) {
 }
}

const loadCurrencies = async () => {
 try {
   await store.dispatch('company/loadCurrencies')
 }catch (e) {
 }
}

const loadScrollContacts = async (data) => {
 try {
   await store.dispatch('contacts/loadScrollContacts', data)
 }catch (e) {
 }
}

const loadNextContacts = async (data) => {
 try {
   await store.dispatch('contacts/loadNextContacts',data)
 }catch (e) {
 }
}

function openModalCreateContact() {
 $('#contactCreateModal').modal('toggle')
}

async function chargeContacts() {
 await loadScrollContacts({ search: search.value, filter: filter.value, type: contactType.value }).finally(() => initScroll() )
}

async function onScroll() {
 if (
     $($contacts.value).height() + $($contacts.value).scrollTop() + 1 >= $($contacts.value).prop('scrollHeight') &&
     getScrollContacts.value.length < getScrollContactsCount.value
 ) {
   removeScrollEvent()

   let prevHeight = $contacts.value.scrollTop

   await loadNextContacts({ search: search.value, filter: filter.value }).finally(() =>   initScroll())

   await nextTick(() => {
     $contacts.value.scrollTop - prevHeight
   })



 }
}

function initScroll(){
 if(!$contacts.value){
   $contacts.value = document.getElementById('contenu')
 }
 if($contacts.value){
   if (getScrollContacts.value.length < getScrollContactsCount.value) {
     $contacts.value?.addEventListener('scroll', onScroll)
   }
 }
}

function removeScrollEvent(){
  if(!$contacts.value){
    $contacts.value = document.getElementById('contenu')
  }
  if($contacts.value){
    $contacts.value?.removeEventListener('scroll', onScroll)
  }

}
</script>

<style scoped>
#contenu {
 padding-right: 15px;
 height: calc(90vh - 240px) !important;
 overflow-y: scroll;
 overflow-x: hidden;
}

#contenu::-webkit-scrollbar-track {
 -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
 border-radius: 8px;
 background-color: #F5F5F5;
}

#contenu::-webkit-scrollbar {
 width: 10px;
 background-color: #F5F5F5;
}

#contenu::-webkit-scrollbar-thumb {
 border-radius: 8px;
 -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
 background-color: #bcbaba;
}


.contact-card {
  height: 330px !important;
  border-radius: 10px;
}

.contactList {
  margin-bottom: 0;
  margin-top: 0;
  padding: 2px 35px;
  width: 100%;
}
</style>
