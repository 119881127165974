<template>
  <div :class="[containerClass || 'my-10 text-center']">
    <div class="image-input rounded-circle" :class="notEmpty ? '' : 'image-input-empty'"  data-kt-image-input="true" :style="{backgroundImage: 'url(' + displayImage +')'}">
      <div class="image-input-wrapper img-fluid rounded-circle" :class="[imageClass || 'w-125px h-125px']" :style="{backgroundImage: 'url(' + displayImage +')'}"></div>

      <label class="btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow"
             data-kt-image-input-action="change"
             data-bs-toggle="tooltip"
             data-bs-dismiss="click"
             :title="$t('changeAvatar')">
        <i class="bi bi-pencil-fill fs-6"><span class="path1"></span><span class="path2"></span></i>

        <input v-on:change="onImageChange" type="file" name="avatar" accept=".png, .jpg, .jpeg"  />
        <input type="hidden" name="avatar_remove" />
      </label>
      <span class="btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow"
            data-kt-image-input-action="cancel"
            data-bs-toggle="tooltip"
            data-bs-dismiss="click"
            :title="$t('cancelAvatar')">
        <i class="bi bi-x fs-3"></i>
      </span>
      <span v-on:click="removeImage" class="btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow"
            data-kt-image-input-action="remove"
            data-bs-toggle="tooltip"
            data-bs-dismiss="click"
            :title="$t('removeAvatar')">
        <i class="bi bi-x fs-3"></i>
      </span>
    </div>
    <div class="text-muted fs-7">{{ $t('contacts.type_file') }}</div>
  </div>
</template>


<script>
export default {
  props: {
    modelImage: {
      type: String,
      default: ''
    },
    imageClass: {
      type: String,
      default: ''
    },
    containerClass: {
      type: String,
      default: ''
    },
    blank: {
      type: Boolean,
      default: false
    }
  },
  data() {
      return {
          newImage: null
      }
  },
  watch: {
    modelImage(newVal) {
      this.newImage = newVal;
    }
  },
  computed: {
    displayImage() {
      return this.newImage ? this.newImage : (this.modelImage || '/assets/media/avatars/img_default.jpg');
    },
    notEmpty() {
      return !!(this.newImage || this.modelImage);
    }
  },
  methods: {
    onImageChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;

      const imageUrl = URL.createObjectURL(files[0]);
      this.newImage = imageUrl;

      this.$emit('update:image', files[0]);
    },
    removeImage() {
      this.newImage = null;
      this.$emit('update:image', null);
    }
  }
};
</script>

<style scoped>
.image-input [data-kt-image-input-action=change] {
    left: 80%;
    top: 10%;
}

.image-input [data-kt-image-input-action=cancel], .image-input [data-kt-image-input-action=remove] {
    position: absolute;
    left: 80%;
    top: 90%;
}
</style>
