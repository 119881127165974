import * as types from './mutation-types'

export const loadDashboardStats = ({ commit }, query) => {
  return new Promise((resolve, reject) => {
    window.axios.get(`dashboard/statistics?startDate=${query.start_date}&endDate=${query.end_date}`).then((response) => {
      commit(types.LOAD_DASHBOARD_STATS, response.data)
      resolve(response)
    }).catch((err) => {
      reject(err)
    })
  })
}

export const loadStatistics = ({ commit }, query) => {
  return new Promise((resolve, reject) => {
    window.axios.get(`get/statistics?startDate=${query.start_date}&endDate=${query.end_date}`).then((response) => {
      commit(types.LOAD_STATISTICS, response.data)
      resolve(response)
    }).catch((err) => {
      reject(err)
    })
  })
}

export const loadSaleStatistics = ({ commit }, query) => {
  return new Promise((resolve, reject) => {
    window.axios.get(`get/sales/statistics?startDate=${query.start_date}&endDate=${query.end_date}`).then((response) => {
      commit(types.LOAD_SALES_STATISTICS, response.data)
      resolve(response)
    }).catch((err) => {
      reject(err)
    })
  })
}

export const getDownloadContactStatistics = ({commit}, query) => {
  return new Promise((resolve, reject) => {
      window.axios.get(`download/contactsSheet?startDate=${query.start_date}&endDate=${query.end_date}&contact_id=${query.contact_id}`, {
        responseType: 'blob'
    }).then((response) => {
          resolve(response)
      }).catch((err) => {
          reject(err)
      })
  })
}

export const getDownloadDashboardStatistics = ({commit}, query) => {
  return new Promise((resolve, reject) => {
      window.axios.get(`download/statistics/dashboard?startDate=${query.start_date}&endDate=${query.end_date}`, {
        responseType: 'blob'
      }).then((response) => {
          resolve(response)
      }).catch((err) => {
          reject(err)
      })
  })
}


