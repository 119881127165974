import * as types from './mutation-types'


export const loadAppointments = ({commit}) => {
    return new Promise((resolve, reject) => {
        window.axios.get('get/appointment/collection').then((response) => {
            commit(types.LOAD_APPOINTMENTS, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const loadCalendarAppointments = ({commit}, query) => {
    return new Promise((resolve, reject) => {
        window.axios.get(`get/calendar/appointment?startDate=${query.start_date}&endDate=${query.end_date}&appointmentType=${query.type == 'all' ? '' : query.type}&search=${query.search}&status=${query.status == 'all' ? '' : query.status}&rdv_created=${query.rdv_created}&rdv_guested=${query.rdv_guested}`).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const getAppointmentById = ({commit}, id) => {
    return new Promise((resolve, reject) => {
        window.axios.get('show/'+id+'/appointment').then((response) => {
            commit(types.SET_CURRENT_APPOINTMENT, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const createAppointment = ({commit}, data) => {

    return new Promise((resolve, reject) => {
        window.axios.post('store/appointment', data).then((response) => {
            resolve(response)
            commit(types.ADD_APPOINTMENT, response.data)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const updateAppointment = ({commit}, data) => {
    return new Promise((resolve, reject) => {
        window.axios.patch('update/' + data.id + '/appointment', data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const deleteAppointment = ({commit}, id) => {
    return new Promise((resolve, reject) => {
        window.axios.delete('delete/' + id + '/appointment').then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const loadAppointmentStatus = ({commit}) => {
    return new Promise((resolve, reject) => {
        window.axios.get('appointment/status/enum').then((response) => {
            commit(types.LOAD_APPOINTMENT_STATUS, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const cancelAppointment = ({commit}, data) => {
    return new Promise((resolve, reject) => {
        window.axios.patch('cancel/appointment/'+ data.appointment_id, data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const reportAppointment = ({commit}, data) => {
    return new Promise((resolve, reject) => {
        window.axios.patch('reschedule/appointment/'+ data.appointment_id, data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const changeStatusAppointment = ({commit}, data) => {
    return new Promise((resolve, reject) => {
        window.axios.patch('update/appointment/'+ data.id + '/status', data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const addMembersAppointment = ({commit}, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post('add/appointment/'+ data.appointment_id + '/participants', data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const removeMembersAppointment = ({commit}, data) => {
    return new Promise((resolve, reject) => {
        window.axios.delete('remove/appointment/'+ data.id + '/participants', data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const addNoteAppointment = ({commit}, data) => {
    let formData = new FormData();
    formData.append('appointment_id', data.appointment_id);
    formData.append('note', data.note);
    data.files.forEach((file, index) => {
        formData.append(`files[${index}]`, file);
    })
    formData.append('_method', 'POST');

    return new Promise((resolve, reject) => {
        window.axios.post('add/appointment/'+ data.appointment_id + '/note', formData, data, {headers: {'Content-type': 'multipart/form-data'}}).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const updateNoteAppointment = ({commit}, data) => {
    let formData = new FormData();
    formData.append('appointment_id', data.appointment_id);
    formData.append('appointment_note_id', data.appointment_note_id);
    formData.append('note', data.note);
    data.files.forEach((file, index) => {
        formData.append(`files[${index}]`, file);
    })
    data.detach_files.forEach((file, index) => {
        formData.append(`detach_files[${index}]`, file);
    })
    formData.append('_method', 'PATCH');

    return new Promise((resolve, reject) => {
        window.axios.post('update/appointment/'+ data.appointment_id + '/note/'+ data.appointment_note_id, formData, data, {headers: {'Content-type': 'multipart/form-data'}}).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const deleteNoteAppointment = ({commit}, data) => {
    return new Promise((resolve, reject) => {
        window.axios.delete('delete/appointment/'+ data.appointment_id + '/note/'+ data.note_id, data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const setAppointmentPresenceStatus = ({commit}, data) => {
    return new Promise((resolve, reject) => {
        window.axios.patch('set/appointment/'+ data.id + '/presence', data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const sendAppointmentByEmailToParticipants = ({commit}, id) => {
    return new Promise((resolve, reject) => {
        window.axios.post('sent/appointment/'+ id + '/email').then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const shareAppointmentDetailsToEmails = ({commit}, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post('share/appointment/'+ data.id + '/email', data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const loadAppointmentReminderStatus = ({commit}) => {
    return new Promise((resolve, reject) => {
        window.axios.get('appointment/reminder/status/enum').then((response) => {
            commit(types.LOAD_APPOINTMENT_REMINDER_STATUS, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const loadTimeZones = ({commit}) => {
    return new Promise((resolve, reject) => {
        window.axios.get('get/timeZone/collection').then((response) => {
            commit(types.LOAD_TIME_ZONES, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const loadAvailableStatus = ({commit}) => {
    return new Promise((resolve, reject) => {
        window.axios.get('appointment/available/enum').then((response) => {
            commit(types.LOAD_AVAILABLE_STATUS, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const loadFrequencyEnum = ({commit}) => {
    return new Promise((resolve, reject) => {
        window.axios.get('appointment/frequency/enum').then((response) => {
            commit(types.LOAD_FREQUENCY_ENUM, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const loadRecurrenceLimitEnum = ({commit}) => {
    return new Promise((resolve, reject) => {
        window.axios.get('appointment/recurrence_limit/enum').then((response) => {
            commit(types.LOAD_RECURRENCE_LIMIT_ENUM, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const loadRecurrenceFrequencyEnum = ({commit}) => {
    return new Promise((resolve, reject) => {
        window.axios.get('appointment/recurrence_frequency/enum').then((response) => {
            commit(types.LOAD_RECURRENCE_FREQUENCY_ENUM, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const setParticipantAvailability = ({commit}, data) => {
    return new Promise((resolve, reject) => {
        window.axios.patch('set/appointment/availability', data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const getAppointment = ({commit}, id) => {
    return new Promise((resolve, reject) => {
        window.axios.get('get/'+id+'/appointment').then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}