import * as types from './mutation-types'
import {SET_CRM_CURRENT_ADVANCE_PAYMENT} from "./mutation-types";
/* eslint-disable */


export const getAdvancePaymentById = ({commit}, id) => {
    return new Promise((resolve, reject) => {
        window.axios.get('show/'+id+'/advance').then((response) => {
            commit(types.SET_CRM_CURRENT_ADVANCE_PAYMENT, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}


export const sendAdvancePayment = ({commit}, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post('send/advance/'+ data.advanceId + '/email', data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const cancelAdvancePaymentMail = ({commit}, id) => {
    return new Promise((resolve, reject) => {
        window.axios.post('cancel-email/advance/'+ id + '/email').then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const downloadAdvancePayment = ({commit}, id) => {
    return new Promise((resolve, reject) => {
        window.axios.get(`get/advance/pdf/${id}`, {
            responseType: 'blob'
        }).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}


export const validateAdvancePayment = ({commit}, data) => {
    let formData = new FormData();
    data.files?.forEach((file, index) => {
        formData.append(`files[${index}]`, file);
    });
    formData.append('payment_date', data.payment_date)
    formData.append('advance_invoice_id', data.advance_invoice_id)
    formData.append('_method', 'POST')

    return new Promise((resolve, reject) => {
        window.axios.post('update/advance/' + data.advance_invoice_id + '/status', formData, {headers: {'Content-type': 'multipart/form-data'}}).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}


export const cancelAdvancePayment = ({commit}, data) => {
    return new Promise((resolve, reject) => {
        window.axios.patch('cancel/advance/' + data.advance_invoice_id, data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}