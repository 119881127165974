<template>
  <div class="btn btn-icon btn-icon-muted btn-active-light btn-active-color-primary shadow-sm w-30px h-30px w-md-40px h-md-40px"
       data-bs-toggle="tooltip"
       data-bs-custom-class="tooltip-dark"
       data-bs-placement="top"
       :title="$t('tooltip.by_suite_apps')"
       data-kt-menu-trigger="click"
       data-kt-menu-attach="parent"
       data-kt-menu-placement="bottom-end"
       style="background: linear-gradient(rgba(0, 114, 206, 0.1), rgba(240, 120, 23, 0.1));"
  >
    <img src="/assets/media/plus.png" />
  </div>
<!--  <div class="menu menu-sub menu-sub-dropdown menu-column w-100 w-sm-350px" data-kt-menu="true" style="">
    <div class="card">
      <div class="card-header">
        <div class="card-title">Apps</div>
      </div>
      <div class="card-body py-5">
        <div class="mh-450px scroll-y me-n5 pe-5">
          <div class="row g-2">
            <div v-for="(module, index) in modules" :key="index" class="col-4">
              <ModuleBadge :module="module" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>-->
</template>

<script setup>

import {nextTick, onMounted, onUpdated, ref} from "vue";
import ModuleBadge from "@/components/Module/ModuleBadge.vue";


const modules = ref([
  {
    name: i18n.global.t('menu.invoices'),
    color: '#0c0875',
    logo: '/assets/media/logos/kobo.png'
  },
  {
    name: i18n.global.t('menu.contacts'),
    color: '#ef9258',
    logo: '/assets/media/logos/kshop.png'
  }
])



</script>

<style scoped>
</style>
